import {  useContext } from 'react'
import { RouterContext } from '../Router'
import { observer } from 'mobx-react'
import { useMst } from 'app/store'
import { getLabel, unitConversion } from '../../../utils/helpers'

const BroomstickTable = observer(() => {
    const { locationState } = useContext(RouterContext)
    const {store: {  WellSectionM }} = useMst()


    return (
        <div className="">
            <h2 style={{ color: 'dodgerblue', marginLeft: '8px' }}>Torque Parameters</h2>
            <div className="" style={{ width: '100%' }}>
                <div style={{ width: '100%' }}>
                    <div className="broomstickTableSettings">
                        <div className="broomstick-table-labels">
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>Tripping Speed</div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>Rotary Speed</div>
                        </div>
                        <div className="broomstickContextValues">
                            <div className="broomstick-field" style={{ marginBottom: '10px' , display: 'flex'  }}>
                                <input 
                                id="broomstick-input"
                                className="broomstick-input" 
                                onFocus={e => e.target.select()}
                                type="number"
                                defaultValue={locationState?.units === 'hybrid' ? unitConversion('distanceTime', 'imperial', 'in', WellSectionM?.tripSpeed).toString() : WellSectionM?.tripSpeed as keyof typeof WellSectionM} 
                                onChange={(e) => {
                                    WellSectionM?.updateWellSectionData(
                                        WellSectionM?.id, 
                                        'tripSpeed', 
                                        'string', 
                                        locationState?.units === 'hybrid' ? unitConversion('distanceTime', 'imperial', 'in', parseFloat(e.target.value), 15).toString() : e.target.value)}} />
                                <div className="item-unit" style={{ fontSize: '12pt', marginLeft: '3px' }}>
                                  ({getLabel('distanceTime', locationState)})
                                </div>
                            </div>
                            <div className="broomstick-field" style={{ marginBottom: '10px', display: 'flex' }}>
                                <input 
                                id="rotarySpeed"
                                className="broomstick-input" 
                                onFocus={e => e.target.select()}
                                type="number"
                                defaultValue={WellSectionM?.rotarySpeed} 
                                onChange={(e) => {
                                    WellSectionM?.updateWellSectionData(
                                        WellSectionM?.id, 
                                        'rotarySpeed', 
                                        'string', 
                                        e.target.value)}} />
                                <div className="item-unit" style={{ fontSize: '12pt', marginLeft: '3px' }}>rpm</div>
                            </div>
                        </div>
                        <div className="broomstick-table-labels">
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>Max FF</div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>Min FF</div>
                            <div className="nowrap h25" style={{ marginBottom: '10px' }}>FF Inclination</div>
                        </div>
                        <div className="broomstickContextValues">
                            <div className="broomstick-field" style={{ marginBottom: '10px' }}>
                                <input
                                    id="maxFf"
                                    onFocus={e => e.target.select()}
                                    type="number"
                                    defaultValue={WellSectionM?.maxFf}
                                    className="broomstick-input"
                                    onChange={(e) => {
                                        WellSectionM?.updateWellSectionData(
                                            WellSectionM?.id,
                                            'maxFf',
                                            'string',
                                            e.target.value
                                        )
                                       
                                    }}
                                />
                            </div>
                            <div className="broomstick-field" style={{ marginBottom: '10px'}}>
                                <input
                                    id="minFf"
                                    onFocus={e => e.target.select()}
                                    type="number"
                                    defaultValue={WellSectionM?.minFf}
                                    className="broomstick-input"
                                    onChange={(e) => {
                                        WellSectionM?.updateWellSectionData(
                                            WellSectionM?.id,
                                            'minFf',
                                            'string',
                                            e.target.value
                                        )
                                        
                                    }}
                                />
                            </div>
                            <div className="broomstick-field" style={{ marginBottom: '10px' }}>
                                <input
                                    id="ffInc"
                                    onFocus={e => e.target.select()}
                                    type="number"
                                    defaultValue={WellSectionM?.ffInc}
                                    className="broomstick-input"
                                    onChange={(e) => {
                                        WellSectionM?.updateWellSectionData(
                                            WellSectionM?.id,
                                            'ffInc',
                                            'string',
                                            e.target.value
                                        )
                                        
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ height: '50px' }}>&nbsp;</div>

            </div>
        </div>
    )
})

export default BroomstickTable