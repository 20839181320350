import { observer } from 'mobx-react'
import { ImportButton } from '../kit/SmallWidthButton'
import { ChangeEventHandler, useContext } from 'react'
import { useMst } from 'app/store'
import * as xlsx from 'xlsx'
import { RouterContext } from '../Router'
import { Link } from 'react-router-dom'
import excel from '../../../assets/images/icons/icon_excel.svg'
import { unitConversion } from '../../../utils/helpers'

const TableToolbar = observer(() => {
  const {
    store: { WellM },
  } = useMst()

  const { locationState } = useContext(RouterContext)

  const checkDuplicates = (mdepth: number) => {
    return WellM?.Survey?.rows ? (WellM?.Survey.rows.find((row) => row.mdepth === mdepth) ? true : false) : false;
  } 

  const handleSpreadsheet: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files;
    if (!file) return
    if (file[0] instanceof Blob) {
      const reader = new FileReader()
      
      reader.onload = (e) => {
        if (e.target?.result instanceof ArrayBuffer) {
          const data = new Uint8Array(e.target.result)
          // read data
          const book = xlsx.read(data, { type: 'buffer' })

          const ws = book.Sheets[book.SheetNames[0]]; // get the first worksheet
          const rawJson : any[] = xlsx.utils.sheet_to_json(ws); // generate objects

          for(const row of rawJson) {
            const mdepth = row.Column1 === '' ? Number.NaN : unitConversion('lengthMedium', locationState?.units, 'in', Number(row.Column1));
            const inclination = row.Column2 === '' ? Number.NaN : Number(row.Column2);
            const azimuth = row.Column3 === '' ? Number.NaN : Number(row.Column3);
            if(!Number.isNaN(mdepth) && mdepth >= 0 && !Number.isNaN(inclination) && !Number.isNaN(azimuth)) {
             if(checkDuplicates(mdepth)) { 
                WellM?.Survey.deleteSurveyRow(mdepth);
             }
             WellM?.Survey.createSurveyRow({mdepth, inclination, azimuth});
            }
          } 
          WellM?.Survey.sortSurveyRows();            
        }
      }
      reader.readAsArrayBuffer(file[0])
    }
    const elem = document.getElementById('icon-button-file') as HTMLInputElement
    if (elem) { elem.value = '' }
  }


  return (
    <div id="tableToolbar" >
      <div className="table-toolbar">
        <div
          style={{
            marginLeft: '12px',
            display: 'flex',
            alignItems: 'center',
            width: '180px',
          }}
        >
          <Link to="/assets/file/template/tally/TallyImport.xls" target="_blank">
            <img alt="excel" src={excel} style={{ marginTop: '6px', padding: '0px', width: '17px', height: '17px' }} />
          </Link>

          <input
            style={{ display: 'none' }}
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            id="icon-button-file"
            type="file"
            multiple={false}
            onChange={handleSpreadsheet}
          />
          <label htmlFor="icon-button-file">
            <ImportButton />
          </label>
        </div>
      </div>
      <div id="importFileMessage" className="popupAlert" />
    </div>
  )
})

export default TableToolbar
