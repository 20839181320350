import { Link } from 'react-router-dom'

const Links = () => {
  return (
    <div className="uiContainer uiContainerShallow doNotPrint" style={{ flexDirection: 'column', display: 'flex' }}>
      <div className="uiContainerTitle">Quick Links</div>
      <div className="uiContainerQuickLinkWrapper">
        <Link to="/all-wells" className="uiContainerQuickLink">
          Wells
        </Link>
        <Link to="/library/tubulars" className="uiContainerQuickLink">
          Tubulars
        </Link>
        <Link to="/RotatingHours" className="uiContainerQuickLink">
          Rotating Hours
        </Link>
      </div>
    </div>
  );
}

export default Links
