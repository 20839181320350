import { useEffect, useContext } from 'react';
import { CompanyContext, RigContext } from 'App'
import Help from './Help'
import Profile from './Profile'
import Links from './Links'
import Summary from './Summary'
import Activity from './Activity.jsx'
import { observer } from 'mobx-react-lite'
import { useQuery, gql } from '@apollo/client'



const DashboardLayout = observer(() => {
    const { company } = useContext(CompanyContext)
    const { rig } = useContext(RigContext)

    const { data, loading, refetch } = useQuery(
        gql`
            query getWellSections($companyId: String!, $rigId: String!, $take: String!) {
                getWellSections(
                    companyId: $companyId,
                    rigId: $rigId,
                    take: $take,
                ) {
                    id
                    name
                    type
                    createdAt
                    updatedAt
                    data
                    well {
                        id
                        name
                        data
                        status
                    }
                }
            }
        `,
        {
            variables: {
                companyId: company?.id ? company?.id : '-',
                rigId: rig?.id ? rig.id : '-',
                take: '20',
            },
        }
    )

    useEffect(() => {
        if (company?.id !== '') {
            refetch()
        }
    }, [company?.id, refetch])

   
    return loading || !(data) ? null : (
        <div className="dashboardWrapper">
            <div className="dashboardContainer">
                <Summary
                    queryData={data}
                    queryLoading={loading}
                />
                <Links />
                <Profile />
                <Activity 
                    queryData={data}
                    queryLoading={loading}
                    />
                <Help />
            </div>
        </div>
    );
})

export default DashboardLayout
