import { EditingModeEnum } from 'app/models/PipeTally/index'
import { pipeNameGen } from 'app/models/PipeTally/Pipe'
import { useMst } from 'app/store'
import { produce } from 'immer'
import { observer } from 'mobx-react'
import { RouterContext } from '../Router'
import { ChangeEventHandler, useState, useCallback, useContext, Fragment, FormEventHandler } from 'react'
import Dialog from '../kit/Dialog'
import Input from '../kit/Input'
import { RadioButton } from '../kit/RadioButton'
import { Button, AddButton } from '../kit/SmallWidthButton'
import IntermediatePipeList, { IntermediatePipes } from './IntermediatePipeList'
import Select from '../kit/Select'
import { unitConversion } from '../../../utils/helpers'

const initialState = {
  length: '',
  serialNumber: '',
  position: 'before' as 'before' | 'after',
  toolCheckbox: false,
}

const AddPipe = observer(({ open, onClose: oc }: AddPipeProps) => {
  const {
    store: { PipeTally },
  } = useMst()
  const [form, setForm] = useState(initialState)
  const [list, setList] = useState<IntermediatePipes>([])
  const [ currentWildSingle, setCurrentWildSingle ] = useState('')
  const { locationState } = useContext(RouterContext)
  const [ toolCheckbox, setToolCheckbox ] = useState(false);

  const onClose = useCallback(() => {
    oc()
    setTimeout(() => {
      setToolCheckbox(false)
      setCurrentWildSingle('')
      setForm(initialState)
      setList([])
    }, 250)
  }, [oc])

  const handleLength: ChangeEventHandler<HTMLInputElement> = (e) => {
    setForm(
      produce(form, (draft) => {
        draft.length = e.target.value
      })
    )
  }

  const handleToolCheckbox:ChangeEventHandler<HTMLInputElement> = (e) => {
    setToolCheckbox(!toolCheckbox);
 }

  const handleSerialNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
    setForm(
      produce(form, (draft) => {
        draft.serialNumber = e.target.value
      })
    )
  }

  const handleWildSingleChange: FormEventHandler<HTMLSelectElement> = (e) => {
    setCurrentWildSingle((e.target as HTMLInputElement).value);
  }

  const handleAddWildSingle = () => {
    setList(
      produce(list, (draft) => {
        const selectedWildSingle = PipeTally?.currentString.wildSingles.find((p) => p.serialNumber === currentWildSingle)
        if(selectedWildSingle) {
          draft.push({
            length: selectedWildSingle.length.toString(),
            serialNumber:  selectedWildSingle.serialNumber.toString(),
            toolCheckbox: selectedWildSingle.isTool,
          })
        }
      })
    )
  }

  const handleAdd = () => {
    setList(
      produce(list, (draft) => {
        draft.push({
          length: form.length,
          serialNumber: form.serialNumber || pipeNameGen(),
          toolCheckbox: toolCheckbox,
        })
      })
    )
    setForm(
      produce(form, (draft) => {
        draft.length = ''
        draft.serialNumber = ''
      })
    )
  }

  const handleSubmit = () => {
    PipeTally?.currentString.list.addData(
      list.map((pipe) => ({
        length: Number(unitConversion('lengthMedium', locationState?.units, 'in', parseFloat(pipe.length), 10)),
        serialNumber: pipe.serialNumber,
        isTool: pipe.toolCheckbox,
      })),
      {
        position: form.position,
        targetId:
          PipeTally.currentString.list.selected.toJSON()[0] || undefined,
          editingMode: PipeTally.editingMode as EditingModeEnum,
      }
    )
    if (PipeTally) {
      // console.log(JSON.stringify(getSnapshot(PipeTally), null, 2))
    }
      
    if(PipeTally?.currentString?.wildSingles) {
      for(const intermediatePipe of list) {
        const wildSingleRowIdx = PipeTally.currentString.wildSingles.findIndex((w) => w.serialNumber.toString() === intermediatePipe.serialNumber)
        if(wildSingleRowIdx > -1) PipeTally.currentString.removeWildSingleRow(wildSingleRowIdx)
      }  
    }
    onClose()
  }


  const handlePosition = {
    before() {
      setForm(
        produce(form, (draft) => {
          if (draft.position !== 'before') draft.position = 'before'
        })
      )
    },
    after() {
      setForm(
        produce(form, (draft) => {
          if (draft.position !== 'after') draft.position = 'after'
        })
      )
    },
  }
  return (
    <Dialog
      style={{ padding: '0px 32px 24px 32px' }}
      open={open}
      onClose={onClose}
    >
      <h3 style={{ textAlign: 'center' }}>Add Pipes</h3>
      <div
        style={{ display: 'flex', alignItems: 'baseline', marginBottom: 32 }}
      >
        <Input
          style={{ width: 200, marginRight: 16 }}
          id="pipe-length-input"
          title="Length"
          type="number"
          value={form.length}
          onChange={handleLength}
          step="0.1"
          className="inputModalField"
          labelClassName="placeholder"
          wrapperClassName="field"
        />
        <Input
          style={{ width: 200, marginRight: 16 }}
          id="pipe-serial-number-input"
          title="Serial number"
          type="text"
          value={form.serialNumber}
          onChange={handleSerialNumber}
          className="inputModalField"
          labelClassName="placeholder"
          wrapperClassName="field"
        />
        {/* Tool Checkbox */}
        <div>
          <div className='pipe-tool-list-checkbox' style={{ display: 'flex' , alignItems: 'center'}}>
              <p className='pipe-tool-list-checkbox-label'>Tool</p>
              <label
                className='pipe-tool-list-checkbox-label'
                htmlFor={'tool-checkbox'}
                style={{ color: '#fff', textWrap: 'nowrap' }}>
              </label>
              <input
                id="tool-checkbox"
                type="checkbox"
                checked={toolCheckbox}
                onChange={handleToolCheckbox}
              />
          </div>
        </div>
        <AddButton
          disabled={!form.length}
          style={{ marginBottom: 4, transform: 'translateY(-2px)' }}
          title="Add"
          onClick={handleAdd}
        />
      </div>

      <div>
        <div className="wild-single-select" style={{display: 'flex', alignItems: 'baseline', width: '100%', gap: '1rem' }}>
            <Select
              id="wildSinglesList"
              title="Wild Single"
              value={currentWildSingle}
              onChange={handleWildSingleChange}
              style={{flexGrow: '1', width: '100%'}}
              >
              <option key="select-wild-single" value="">Select a wild single:</option>
              {PipeTally?.currentString.wildSingles.length !== 0 ?
                PipeTally?.currentString.wildSingles.map((s) => 
                  list.find((p) => p.serialNumber === s.serialNumber) ? ''
                  : (
                  <option key={s.serialNumber} value={s.serialNumber}>{s.serialNumber}  ( {Number(s.length).toFixed(3)} )</option>
                )) : '' }
            </Select>
            <AddButton
                disabled={!PipeTally?.currentString.wildSingles.length || !currentWildSingle}
                style={{ marginBottom: 4, transform: 'translateY(-2px)' }}
                title="Add"
                onClick={handleAddWildSingle}
              />
          </div>
          
      </div>

      {list.length !== 0 ? (
        <Fragment>
          <h4
            style={{
              marginLeft: 2,
              marginBottom: 8,
              marginTop: 36,
              width: '100%',
            }}
          >
            Intermediate pipe list
          </h4>
          <IntermediatePipeList
            data={list as IntermediatePipes & string}
            setData={setList}
          />
        </Fragment>
      ) : (
        <></>
      )}

      {PipeTally?.currentString.length !== 0 ? (
        <>
          <h4
            style={{
              marginLeft: 2,
              marginBottom: -8,
              marginTop: 36,
              width: '100%',
            }}
          >
            Position relative to the order in the hole
          </h4>
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginLeft: 10,
            }}
          >
            <div className="direction-picker" onClick={handlePosition.before}>
              <RadioButton
                id="select-before"
                checked={form.position === 'before'}
                onChange={() => {}}
              />
              <p className="direction-label">Below</p>
            </div>
            <div className="direction-picker" onClick={handlePosition.after}>
              <RadioButton
                id="select-after"
                style={{ marginLeft: 30 }}
                checked={form.position === 'after'}
                onChange={() => {}}
              />
              <p className="direction-label">Above</p>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          marginTop: '24px',
        }}
      >
        <Button 
          small 
          disabled={list.length === 0} 
          onClick={handleSubmit}>
          Submit
        </Button>
        <Button
          small
          secondary
          style={{ marginRight: '8px' }}
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  )
})

type AddPipeProps = {
  open: boolean
  onClose: () => void
}

export default AddPipe
