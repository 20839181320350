import { useNavigate } from 'react-router'
import { useMst } from 'app/store'
import { spaceToUnderscore } from 'utils/helpers'
import { underscoreToSpace } from 'utils/helpers'


const Head = () => (
  <thead>
    <tr>
      <th>Well</th>
      <th>Section</th>
      <th>Location</th>
      <th>Date Created</th>
      <th>Date Updated</th>
    </tr>
  </thead>
)

const Activity = ({queryData, queryLoading}) => {
    const {
        store: { getWellM, getWellSectionM, getTrendSheet, getKillSheet, getOverpullCalculator },
      } = useMst()
    const router = useNavigate()


    async function takeShortcut(well, section) {
        const variables = {
            wellName: underscoreToSpace(well),
            wellSectionName: underscoreToSpace(section),
            companyId: JSON.parse(localStorage.getItem('context') || '').company.id,
            rigId: JSON.parse(localStorage.getItem('context') || '').rig.id,
        }

        document.body.style.cursor='wait'
        try {
            await getWellM(variables)
            await getWellSectionM(variables)
            await getTrendSheet(variables)
            await getKillSheet(variables)
            await getOverpullCalculator(variables)
            setTimeout(() => {
                router(`/all-wells/${well}/${section}`)
            }, 1500)
        } catch (error) {
            console.log(error)
        }
    }
        return (
            <div className="uiContainer uiContainerWide" style={{ minWidth: "min-content", minHeight: "min-content" }}>
                <div className="uiContainerTitle">Recent Activity</div>

                <table className="well dashboardRecentActivity">
                    <Head />
                    <tbody>
                        {!queryLoading && queryData.getWellSections
                        .toSorted((a, b) => {
                            const aUpdatedDate = (typeof a.data === 'string' ? JSON.parse(a.data) : a.data).UpdatedAt;
                            const bUpdatedDate = (typeof b.data === 'string' ? JSON.parse(b.data) : b.data).UpdatedAt;
                            const aComparisonDate = aUpdatedDate ? aUpdatedDate : new Date(a.updatedAt).getTime();
                            const bComparisonDate = bUpdatedDate ? bUpdatedDate : new Date(b.updatedAt).getTime();
                            return bComparisonDate - aComparisonDate;
                        }).slice(0, 5).map(({ id, name, type, createdAt, updatedAt, well, data: wellSectionData }) => {
                            const parsedData = (typeof wellSectionData === 'string' ? JSON.parse(wellSectionData) : wellSectionData);
                            const setDate = (parsedData.UpdatedAt ?  new Date(parsedData.UpdatedAt) : new Date(updatedAt));
                            const wellSectionUpdated = setDate.toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'});
                    
                        return (
                          <tr
                            key={id}
                            onClick={(e) => {
                              takeShortcut(spaceToUnderscore(well.name), spaceToUnderscore(name))
                            }}
                          >
                            <td className="hl">{well.name}</td>
                            <td className="hl">{name}</td>
                            <td>{type === 'CSG' ? 'Casing' : 'Open hole'}</td>
                            <td>{new Date(createdAt).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'})}</td>
                            <td>{wellSectionUpdated}</td>
                          </tr>
                        )})}
                    </tbody>
                </table>

            </div>
        )
}

export default Activity
