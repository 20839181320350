import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { LOGOUT } from 'api'
import { Link, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router'
import { makeStyles } from '@mui/styles'
import { theme } from '../kit/styles/theme'
import { UndoButtons } from '../kit/UndoButtons'
import { observer } from 'mobx-react'
import { useMst } from 'app/store'

import {
  ChildrenProps,
  JSXElementProps,
  underscoreToSpace
} from 'utils/helpers'
import endeavorLogo from '../kit/icons/EndeavorLogo.svg'
// import { clearCache } from 'utils/cacheClear'
import { useContext, useState, useEffect, forwardRef } from 'react'
import { UserContext, User, CompanyContext, RigContext } from 'App'
import useBreadcrumbs, { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'
import Menu from './Menu'
import { ChipTab } from '../kit/Tabs'
import { ReactComponent as SaveIcon } from '../kit/icons/Save.svg'
import icon_home from '../../../assets/images/icons/icon_home.svg'
import icon_print from '../../../assets/images/icons/icon_print.svg'
import icon_wifi_on from '../../../assets/images/icons/icon_wifi_on.svg'
import icon_wifi_off from '../../../assets/images/icons/icon_wifi_off.svg'
import ViewSidebarOutlinedIcon from '@mui/icons-material/ViewSidebarOutlined'
// import logoOperator from '../../../assets/images/logos/logoOperatorGrey.png'
import { Toast } from '../kit/Toast'
import { AlertProps } from '@mui/material/Alert';
import { HEALTH_CHECK, HEALTH_CHECK_INTERVAL, STATUS_CHECK, STATUS_INTERVAL } from 'app/constants/common'

const useStyles = makeStyles({
  Breadcrumbs: {
    fontWeight: 600,
    fontSize: 'min(max(16px, 1.5vw), 22px)',
    color: theme.colors.text.primary,
    lineHeight: '46px',
    width: '100%',
    "@media print": {
      display: 'none !important',
    },
  },
  PrintBreadcrumbs: {
    fontWeight: 600,
    fontSize: '14px',
    color: theme.colors.text.primary,
    width: '100%',
    "@media screen": {
      display: 'none !important',
    },
  },
  contentBox: {
    paddingLeft: theme.spacing(15),
    paddingRight: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 72px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    color: theme.colors.text.primary,
  },
  paper: {
    backgroundColor: theme.colors.well.bg.secondary,
    padding: theme.spacing(1),
    borderRadius: '24px',
  },
  userInfo: {
    display: 'flex',
    alignContent: 'center',
    whiteSpace: 'nowrap',
    marginLeft: '12px'
  },
})

const AllWellsBreadcrumbFactory = (print: boolean) => {
  return function AllWellsBreadcrumb({ match }: any) {
    const router = useNavigate()
    return (
      <span
        className="clickable_breadcrumb"
        onClick={() => router(`/all-wells`)}
      >
        All Wells
      </span>
    )
  }
}

const WellIdBreadcrumbFactory = (print: boolean) => {
  return function WellIdBreadcrumb({ match }: any) {
    const router = useNavigate()
    const wellId = underscoreToSpace(match.params.wellId)
    return (
      <span
        className="clickable_breadcrumb"
        onClick={() => router(`/all-wells/${match.params.wellId}`)}
        title={wellId}
      >
        {print ? <><span style={{textDecoration: 'underline'}}>Well:</span> {wellId}</>: trimString(wellId, 14)}
      </span>
    )
  }
}

const WellSectionIdBreadcrumbFactory = (print: boolean) => {
  return function WellSectionIdBreadcrumb ({ match }: any) {
    switch (match.params.wellSectionId) {
      case 'well-sections-tab':
        return 'Well Sections';
      case 'kick-tolerance-tab':
        return 'Kick Tolerance';
      case 'survey-tab':
        return 'Survey';
      default:
        {
          const wellSectionId = underscoreToSpace(match.params.wellSectionId)
          return <span title={wellSectionId}>
            {print ? <><span style={{textDecoration: 'underline'}}>Section:</span> {wellSectionId}</>: trimString(wellSectionId, 14)}
          </span>      
        }
    }
  }
}

const routes: BreadcrumbsRoute[] = [
  { 
    path: '/', 
    breadcrumb: null 
  },
  {
    path: '/RotatingHours',
    breadcrumb: 'Rotating Hours'
  },
  {
    path: '/Library',
    breadcrumb: 'Library'
  },
  {
    path: '/all-wells',
    breadcrumb: AllWellsBreadcrumbFactory(false),
  },
  {
    path: '/all-wells/:wellId',
    breadcrumb: WellIdBreadcrumbFactory(false),
  },
  {
    path: '/all-wells/:wellId/:wellSectionId',
    breadcrumb: WellSectionIdBreadcrumbFactory(false),
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/pipe-tally',
    breadcrumb: 'Pipe Tally',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/liner-tally',
    breadcrumb: 'Liner Tally',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/casing-tally',
    breadcrumb: 'Casing Tally',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/bha',
    breadcrumb: 'BHA',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/bop',
    breadcrumb: 'BOP',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/trip-sheet',
    breadcrumb: 'Trip Sheet',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/trend-sheet',
    breadcrumb: 'Trend Sheet',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/overpull-sheet',
    breadcrumb: 'Overpull Sheet',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/broomstick',
    breadcrumb: 'Broomstick',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/kill-sheet',
    breadcrumb: 'Kill Sheet',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/kill-graph',
    breadcrumb: 'Kill Chart',
  },
]

const printRoutes: BreadcrumbsRoute[] = [
  { 
    path: '/', 
    breadcrumb: null 
  },
  {
    path: '/RotatingHours',
    breadcrumb: 'Rotating Hours'
  },
  {
    path: '/Library',
    breadcrumb: 'Library'
  },
  {
    path: '/all-wells',
    breadcrumb: AllWellsBreadcrumbFactory(true),
  },
  {
    path: '/all-wells/:wellId',
    breadcrumb: WellIdBreadcrumbFactory(true),
  },
  {
    path: '/all-wells/:wellId/:wellSectionId',
    breadcrumb: WellSectionIdBreadcrumbFactory(true),
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/pipe-tally',
    breadcrumb: 'Pipe Tally',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/liner-tally',
    breadcrumb: 'Liner Tally',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/casing-tally',
    breadcrumb: 'Casing Tally',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/bha',
    breadcrumb: 'BHA',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/bop',
    breadcrumb: 'BOP',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/trip-sheet',
    breadcrumb: 'Trip Sheet',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/trend-sheet',
    breadcrumb: 'Trend Sheet',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/overpull-sheet',
    breadcrumb: 'Overpull Sheet',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/kill-sheet',
    breadcrumb: 'Kill Sheet',
  },
  {
    path: '/all-wells/:wellId/:wellSectionId/kill-graph',
    breadcrumb: 'Kill Chart',
  },
]

function trimString(string: string, chars: number) {
  if (string.length > chars) {
    return string.substring(0, chars) + '...'
  } else {
    return string
  }
}

export const Breadcrumbs = ({fname = ''}, props: JSXElementProps<HTMLDivElement>) => {
  const cn = useStyles()
  const breadcrumbs = useBreadcrumbs(routes)
  
  return (
    <div {...props} className={cn.Breadcrumbs}>
      {breadcrumbs.map(({ breadcrumb, match }, i, arr) => {
        if (match.pathname === '/all-wells' && arr.length > 2) {
          return null
        } else if (match.pathname === '/dashboard') {
          return <span key={i}>
            <span
              className="dashboardHeaderWelcome"
            >
              Welcome back
            </span>
            <span
              className="dashboardHeaderName"
            >
              {fname}
            </span>
          </span>
        } else if (i === arr.length - 1) {
          return <span key={i}>
            &nbsp;/&nbsp;
            <span
              style={{ color: 'dodgerblue' }}
            >
              {breadcrumb}
            </span>
          </span>
        } else {
          return <span key={i}>&nbsp;/&nbsp;{breadcrumb}</span>
        }
      })}
    </div>
  )
}

export const PrintBreadcrumbs = ({fname = ''}, props: JSXElementProps<HTMLDivElement>) => {
  const location = useLocation()
  const uri = location.pathname
  const cn = useStyles()
  const breadcrumbs = useBreadcrumbs(printRoutes)
  const { company } = useContext(CompanyContext)
  const { rig } = useContext(RigContext)

  return (
    <div {...props} className={cn.PrintBreadcrumbs}>
      {
        uri !== '/dashboard' ? 
        <>
          <span style={{ display: 'inline-block' }}><span style={{textDecoration: 'underline'}}>Company:</span> {company?.name}</span>
          <span style={{ display: 'inline-block' }}>&nbsp;/&nbsp;<span style={{textDecoration: 'underline'}}>Rig:</span> {rig?.name}</span>
        </>
        : null
      }
      {breadcrumbs.map(({ breadcrumb, match }, i, arr) => {
        if (match.pathname === '/all-wells' && arr.length > 2) {
          return null
        } else if (match.pathname === '/dashboard') {
          return <span key={i} style={{ display: 'inline-block' }}>
            <span
              className="dashboardHeaderWelcome"
            >
              Welcome back
            </span>
            <span
              className="dashboardHeaderName"
            >
              {fname}
            </span>
          </span>
        } else if (i === arr.length - 1) {
          return <span key={i} style={{ display: 'inline-block' }}>
            &nbsp;/&nbsp;
            <span
              style={{ color: 'dodgerblue' }}
            >
              {breadcrumb}
            </span>
          </span>
        } else {
          return <span key={i} style={{ display: 'inline-block' }}>&nbsp;/&nbsp;{breadcrumb}</span>
        }
      })}
    </div>
  )
}

export const Paper = forwardRef<
  HTMLDivElement,
  JSXElementProps<HTMLDivElement> & ChildrenProps
>(({ children, ...props }, ref) => {
  const cn = useStyles()
  return (
    <div ref={ref} {...props} className={cn.paper}>
      {children}
    </div>
  )
})

export const UserInfo = ({
  user,
  ...props
}: JSXElementProps<HTMLDivElement> & { user?: User }) => {
  const cn = useStyles()
  return user ? (
    <div {...props} className={cn.userInfo}>
      <h5>
        {user.firstName} {user.secondName}
      </h5>
    </div>
  ) : null
}


type LayoutProps = { sort?: string } & ChildrenProps;

const Layout = observer(({ children, sort }: LayoutProps) => {
  const {
    undoManager,
    store: { WellM, updateTally, updateBha, updateBop, updateLinerTally, updateCasingTally, updateTripSheet, updateTrendSheet, updateKillSheet, updateWellM, updateWellSectionM, updateWellMDate, updateWellSectionDateM, updateOverpullCalculator},
  } = useMst()

  const location = useLocation()
  const uri = location.pathname
  const router = useNavigate()
  
  const { user, setUser } = useContext(UserContext)
  const [msg, setMsg]= useState('')
  const [showMsg, setShowMsg] = useState(false)
  const [variant, setVariant] = useState<AlertProps["color"]>("success")

  const [getStatus] = useLazyQuery(
    gql` query { status } `,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
          if (data.status === false) {
            logout({ variables: { session: localStorage.getItem('session') } })
          }
      },
      onError(error) {
          console.log('fetch-error', error.message)

          if (error.message === 'Not authorized') {
            console.log('Not authorized')
            logout({ variables: { session: localStorage.getItem('session') } })
          }

          if (error.message === 'Network error: Failed to fetch') {
            console.log('There is a network error')
          }
        }
    }
  )


  const terminateSession = () => {
    window.localStorage.removeItem('session')
    setUser && setUser(null)
    router('/login')
  }

  const [logout] =
  useMutation(LOGOUT, {
    onCompleted(data) {
      terminateSession()
      localStorage.removeItem('context')
    },
    onError(error) {
      terminateSession()
      localStorage.removeItem('context')
      console.log('error', error)
    },
  })

  
  const smallScreen = window.matchMedia('only screen and (max-width:1280px)')
  const [menuCollapsed, setMenuCollapsed] = useState(smallScreen.matches)
  /* automatically show/hide menu when screen size crosses 1280px in width */
  smallScreen.addEventListener("change", (e) => {setMenuCollapsed(e.matches)})
  const [role, setRole] = useState('')
  const [userCompanyId, setUserCompanyId] = useState('')
  const [isOnline, setNetwork] = useState(true)
  const { company } = useContext(CompanyContext)
  const { rig } = useContext(RigContext)

  useEffect(() => {
    const viewer: Array<string> = ['read']
    const complete: Array<string> = ['read', 'full']
    const driller: Array<string> = ['read', 'write']
    const supervisor: Array<string> = ['read', 'owner']
    const support: Array<string> = ['read', 'write', 'owner']

        switch (JSON.stringify(user?.permissions)) {
        case JSON.stringify(support):
            setRole('Support')
            break
        case JSON.stringify(complete):
            setRole('Complete')
            break
        case JSON.stringify(viewer):
            setRole('Viewer')
            break
        case JSON.stringify(driller):
            setRole('Driller')
            break
        case JSON.stringify(supervisor):
            setRole('Supervisor')
            break
        default:
            setRole('Viewer')
            break
    }
  }, [user?.permissions])

  useEffect(() => {
    if (showMsg === true) setShowMsg(false)
  }, [showMsg])

  useEffect(() => {
    if(company) setUserCompanyId(company.id)
  }, [uri, company])

  useEffect(() => {
    if (HEALTH_CHECK) {
      setInterval(connection, HEALTH_CHECK_INTERVAL)
    }
    if (STATUS_CHECK) {
      setInterval(session, STATUS_INTERVAL)
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isOnline) {
      setMsg('Internet connection is online')
      setVariant('success')
      setShowMsg(true)
    } else {
      setMsg('Internet connection is offline')
      setVariant('error')
      setShowMsg(true)
    }
  }, [isOnline])

  function session() {
    getStatus()
  }

  function connection() {
    try {
    fetch(process.env.REACT_APP_BASEURL as string + '?' + new Date().getTime(), { cache: 'no-store'})
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          console.log('Health check success')
          setNetwork(true)
        } else {
          setNetwork(false)
          console.log('Health check failure: invalid status code', response.status, response.statusText)
        }
      })
      .catch((error) => {
        setNetwork(false)
        console.log('Health check failure: general exception', error)
      })
    } catch (error) {
        setNetwork(false)
        console.log('Health check general failure', error)
    }
  }

 
  return (
      <div className="pageWrapper">
        {
          menuCollapsed ?
          <div className="pageHomeButton">
            <Link title="Home" to="/dashboard">
              <img alt="home icon" src={icon_home} style={{ height: 20, width: 20 }} />
            </Link>
          </div> :
          <div className="pageSidebar">
            <div className="pageSidebarHeader">
              <Menu/>
            </div>
            <div className="pageSidebarBranding">
              <a target="_blank" rel="noreferrer" href="https://endeavor.one">
                <img src={endeavorLogo} className="endeavorLogo" alt="Endeavor" />
              </a>
            </div>
          </div>
        }
        <div className="pageBody">
          <div className="pageHeader">
            <Breadcrumbs fname={user?.firstName || ''} />
            <PrintBreadcrumbs fname={user?.firstName || ''} />
            <ChipTab
              title={menuCollapsed ? "Show sidebar" : "Hide sidebar"}
              onClick={(e: React.MouseEvent) => {setMenuCollapsed(!menuCollapsed)}}
              id='showHideSidebarButton'
            >
              <ViewSidebarOutlinedIcon style={{ transform: "scaleX(-1)", color: '#5C85F5' }}/>
            </ChipTab>
            <UndoButtons
              undoDisabled={!undoManager.canUndo}
              redoDisabled={!undoManager.canRedo}
              onRedo={undoManager.redo}
              onUndo={undoManager.undo}
            />
            <ChipTab
              onClick={() => {
                window.print();
              }}
              id='printButton'
              title="Print"
            >
              <img alt="print" src={icon_print} />
            </ChipTab>

            { function() : JSX.Element | null {
              switch(sort) {
                case 'wellName':
                  if (
                    role === 'Support' ||
                    (
                      user?.companyId === userCompanyId &&
                      (role === 'Supervisor' || role === 'Driller' || role === 'Complete') &&
                      user?.scope?.includes('rig')
                    )
                  ) {
                    return <ChipTab
                      id="saveButton"
                      title="Save"
                      onClick={() => {
                        if(company && rig)
                        updateWellM({
                          wellName: underscoreToSpace(
                            location.pathname.split('/')[2]
                          ),
                          company: company.id,
                          rig: rig.id,
                          updatedDate: new Date()
                        })
                        .then(() => {setMsg('Well saved!')})
                        .catch(() => {setMsg('Error saving the well'); setVariant("error")})
                        .finally(() => {setShowMsg(true)})
                      }}
                    >
                      <SaveIcon />
                    </ChipTab>
                  } else { return null }
                case 'wellSectionName':
                  if (
                    (
                      role === 'Support'  ||
                      (
                        user?.companyId === userCompanyId &&
                        (role === 'Supervisor' || role === 'Driller' || role === 'Complete') &&
                        user?.scope?.includes('rig')
                      )
                    ) && WellM?.Status !== "completed"
                  ) {
                    return <ChipTab
                      id="saveButton"
                      title="Save"
                      onClick={() => {
                        const now = new Date();
                        if(company && rig) updateWellM({
                          wellName: underscoreToSpace(
                            location.pathname.split('/')[2]
                          ),
                          company: company.id,
                          rig: rig.id,
                          updatedDate: now
                        }).then(
                          () =>{ return updateWellSectionM({
                            wellName: underscoreToSpace(
                              location.pathname.split('/')[2]
                              ),
                            wellSectionName: underscoreToSpace(
                              location.pathname.split('/')[3]
                              ),
                            company: company.id,
                            rig: rig.id,
                            updatedDate: now
                          })
                        })
                        .then(() => {setMsg('Well Section saved!')})
                        .catch(() => {setMsg('Error saving the well section'); setVariant("error")})
                        .finally(() => {setShowMsg(true)})
                      }}
                    >
                      <SaveIcon />
                    </ChipTab>
                  } else { return null }
                case 'pipe-tally':
                  if (
                    (
                      role === 'Support' ||
                      (
                        user?.companyId === userCompanyId &&
                        (role === 'Supervisor' || role === 'Complete') &&
                        user?.scope?.includes('rig')
                      )
                    ) && WellM?.Status !== "completed"
                  ) {
                    return <ChipTab
                      id="saveButton"
                      title="Save"
                      onClick={() => {
                        const now = new Date();
                        if(company && rig) updateTally({
                          wellName: underscoreToSpace(
                            location.pathname.split('/')[2]
                          ),
                          wellSectionName: underscoreToSpace(
                            location.pathname.split('/')[3]
                          ),
                          company: company.id,
                          rig: rig.id,
                          }).then(() => {return updateWellMDate({
                              wellName: underscoreToSpace(
                                location.pathname.split('/')[2]
                              ),
                              company: company.id,
                              rig: rig.id,
                              companyId: company.id,
                              rigId: rig.id,
                              updatedDate: now
                            })
                          }).then(() => {return updateWellSectionDateM({
                            wellName: underscoreToSpace(
                              location.pathname.split('/')[2]
                            ),
                            wellSectionName: underscoreToSpace(
                              location.pathname.split('/')[3]
                            ),
                            company: company.id,
                            rig: rig.id,
                            companyId: company.id,
                            rigId: rig.id,
                            updatedDate: now
                          }).then(
                            () =>{ return updateWellSectionM({
                              wellName: underscoreToSpace(
                                location.pathname.split('/')[2]
                                ),
                              wellSectionName: underscoreToSpace(
                                location.pathname.split('/')[3]
                                ),
                              company: company.id,
                              rig: rig.id,
                              updatedDate: now
                            })
                          })
                        })
                        .then(() => {setMsg('Pipe Tally saved!')})
                        .catch(() => {setMsg('Error saving Pipe Tally'); setVariant("error")})
                        .finally(() => {setShowMsg(true)})
                      }}
                    >
                      <SaveIcon />
                    </ChipTab>
                  } else { return null }
                case 'bop':
                  if (
                    (
                      role === 'Support' ||
                      (
                        user?.companyId === userCompanyId &&
                        (role === 'Supervisor' || role === 'Driller' || role === 'Complete') &&
                        user?.scope?.includes('rig')
                      )
                    ) && WellM?.Status !== "completed"
                  ) {
                    return <ChipTab
                      id="saveButton"
                      title="Save"
                      onClick={() => {
                        if(company && rig) updateBop({
                          wellName: underscoreToSpace(
                            location.pathname.split('/')[2]
                          ),
                          company: company.id,
                          rig: rig.id
                        }).then(() => {return updateWellMDate({
                            wellName: underscoreToSpace(
                              location.pathname.split('/')[2]
                            ),
                            company: company.id,
                            rig: rig.id,
                            companyId: company.id,
                            rigId: rig.id,
                            updatedDate: new Date()
                          })
                        })
                        .then(() => {setMsg('BOP saved!')})
                        .catch(() => {setMsg('Error saving BOP'); setVariant("error")})
                        .finally(() => {setShowMsg(true)})
                      }}
                    >
                      <SaveIcon />
                    </ChipTab>
                  } else { return null }
                case 'bha':
                  if (
                    (role === 'Support' ||
                      (
                        user?.companyId === userCompanyId &&
                        (role === 'Supervisor' || role === 'Driller' || role === 'Complete') &&
                        user?.scope?.includes('rig')
                      )
                    ) && WellM?.Status !== "completed"
                  ) {
                    return <ChipTab
                      id="saveButton"
                      title="Save"
                      onClick={() => {
                        const now = new Date();
                        if(company && rig) updateBha({
                          wellName: underscoreToSpace(
                            location.pathname.split('/')[2]
                          ),
                          wellSectionName: underscoreToSpace(
                            location.pathname.split('/')[3]
                          ),
                          company: company.id,
                          rig: rig.id
                        }).then(() => {return updateWellMDate({
                            wellName: underscoreToSpace(
                              location.pathname.split('/')[2]
                            ),
                            company: company.id,
                            rig: rig.id,
                            companyId: company.id,
                            rigId: rig.id,
                            updatedDate: now
                          })
                        }).then(() => {  updateWellSectionDateM({
                            wellName: underscoreToSpace(
                              location.pathname.split('/')[2]
                            ),
                            wellSectionName: underscoreToSpace(
                              location.pathname.split('/')[3]
                            ),
                            company: company.id,
                            rig: rig.id,
                            companyId: company.id,
                            rigId: rig.id,
                            updatedDate: now
                          })
                        })
                        .then(() => {setMsg('BHA saved!')})
                        .catch(() => {setMsg('Error saving BHA'); setVariant("error")})
                        .finally(() => {setShowMsg(true)})
                      }}
                    >
                      <SaveIcon />
                    </ChipTab>
                  } else { return null }
                case 'casing-tally':
                  if (
                    (
                      role === 'Support' ||
                      (
                        user?.companyId === userCompanyId &&
                        (role === 'Supervisor' || role === 'Driller' || role === 'Complete') &&
                        user?.scope?.includes('rig')
                      )
                    ) && WellM?.Status !== "completed"
                  ) {
                    return <ChipTab
                      id="saveButton"
                      title="Save"
                      onClick={() => {
                        const now = new Date();
                        if(company && rig) updateCasingTally({
                          wellName: underscoreToSpace(
                            location.pathname.split('/')[2]
                          ),
                          wellSectionName: underscoreToSpace(
                            location.pathname.split('/')[3]
                          ),
                          company: company.id,
                          rig: rig.id
                          }).then(() => {return  updateWellMDate({
                              wellName: underscoreToSpace(
                                location.pathname.split('/')[2]
                              ),
                              company: company.id,
                              rig: rig.id,
                              companyId: company.id,
                              rigId: rig.id,
                              updatedDate: now
                            })
                          }).then(() => {return updateWellSectionDateM({
                              wellName: underscoreToSpace(
                                location.pathname.split('/')[2]
                              ),
                              wellSectionName: underscoreToSpace(
                                location.pathname.split('/')[3]
                              ),
                              company: company.id,
                              rig: rig.id,
                              companyId: company.id,
                              rigId: rig.id,
                              updatedDate: now
                            })
                          })
                          .then(() => {setMsg('Casing Tally saved!')})
                          .catch(() => {setMsg('Error saving Casing Tally'); setVariant("error")})
                          .finally(() => {setShowMsg(true)})
                        }}
                      >
                      <SaveIcon />
                    </ChipTab>
                  } else { return null }
                case 'liner-tally':
                  if (
                    (
                      role === 'Support' ||
                      (
                        user?.companyId === userCompanyId &&
                        (role === 'Supervisor' || role === 'Driller' || role === 'Complete') &&
                        user?.scope?.includes('rig')
                      )
                    ) && WellM?.Status !== "completed"
                  ) {
                    return <ChipTab
                      id="saveButton"
                      title="Save"
                      onClick={() => {
                        const now = new Date();
                        if(company && rig) updateLinerTally({
                          wellName: underscoreToSpace(
                            location.pathname.split('/')[2]
                          ),
                          wellSectionName: underscoreToSpace(
                            location.pathname.split('/')[3]
                          ),
                          company: company.id,
                          rig: rig.id
                        }).then(() => {return updateWellMDate({
                          wellName: underscoreToSpace(
                            location.pathname.split('/')[2]
                          ),
                          company: company.id,
                          rig: rig.id,
                          companyId: company.id,
                          rigId: rig.id,
                          updatedDate: now
                        })
                        }).then(() => {return updateWellSectionDateM({
                          wellName: underscoreToSpace(
                            location.pathname.split('/')[2]
                          ),
                          wellSectionName: underscoreToSpace(
                            location.pathname.split('/')[3]
                          ),
                          company: company.id,
                          rig: rig.id,
                          companyId: company.id,
                          rigId: rig.id,
                          updatedDate: now
                        })
                        })
                        .then(() => {setMsg('Liner Tally saved!')})
                        .catch(() => {setMsg('Error saving Liner Tally'); setVariant("error")})
                        .finally(() => {setShowMsg(true)})
                      }}
                    >
                      <SaveIcon />
                    </ChipTab>
                  } else { return null }
                case 'trip-sheet':
                  if (
                    (
                      role === 'Support' ||
                      (
                        user?.companyId === userCompanyId &&
                        (role === 'Driller' || role === 'Complete') &&
                        user?.scope?.includes('rig')
                      )
                    ) && WellM?.Status !== "completed"
                  ) {
                    return <ChipTab
                      id="saveButton"
                      title="Save"
                      onClick={() => {
                        const now = new Date();
                        if(company && rig) updateTripSheet({
                          wellName: underscoreToSpace(
                            location.pathname.split('/')[2]
                          ),
                          wellSectionName: underscoreToSpace(
                            location.pathname.split('/')[3]
                        ),
                        company: company.id,
                        rig: rig.id
                        }).then(() => {return  updateWellMDate({
                            wellName: underscoreToSpace(
                              location.pathname.split('/')[2]
                            ),
                            company: company.id,
                            rig: rig.id,
                            companyId: company.id,
                            rigId: rig.id,
                            updatedDate: now
                          })
                        }).then(() => {return updateWellSectionDateM({
                            wellName: underscoreToSpace(
                              location.pathname.split('/')[2]
                            ),
                            wellSectionName: underscoreToSpace(
                              location.pathname.split('/')[3]
                            ),
                            company: company.id,
                            rig: rig.id,
                            companyId: company.id,
                            rigId: rig.id,
                            updatedDate: now
                          })
                        })
                        .then(() => {setMsg('Trip Sheet saved!')})
                        .catch(() => {setMsg('Error saving Trip Sheet'); setVariant("error")})
                        .finally(() => {setShowMsg(true)})
                      }}
                    >
                      <SaveIcon />
                    </ChipTab>
                  } else { return null }
                case 'trend-sheet':
                  if (
                    (
                      role === 'Support' ||
                      (
                        user?.companyId === userCompanyId &&
                        (role === 'Driller' || role === 'Complete') &&
                        user?.scope?.includes('rig')
                      )
                    ) && WellM?.Status !== "completed"
                  ) {
                    return <ChipTab
                      id="saveButton"
                      title="Save"
                      onClick={() => {
                        const now = new Date();
                        if(company && rig) updateTrendSheet({
                          wellName: underscoreToSpace(
                            location.pathname.split('/')[2]
                          ),
                          wellSectionName: underscoreToSpace(
                            location.pathname.split('/')[3]
                          ),
                          company: company.id,
                          rig: rig.id
                          }).then(() => {return updateWellMDate({
                            wellName: underscoreToSpace(
                              location.pathname.split('/')[2]
                            ),
                            company: company.id,
                            rig: rig.id,
                            companyId: company.id,
                            rigId: rig.id,
                            updatedDate: now
                          })
                          }).then(() => {return updateWellSectionDateM({
                            wellName: underscoreToSpace(
                              location.pathname.split('/')[2]
                            ),
                            wellSectionName: underscoreToSpace(
                              location.pathname.split('/')[3]
                            ),
                            company: company.id,
                            rig: rig.id,
                            companyId: company.id,
                            rigId: rig.id,
                            updatedDate: now
                           })
                        })
                        .then(() => {setMsg('Trend Sheet saved!')})
                        .catch(() => {setMsg('Error saving Trend Sheet'); setVariant("error")})
                        .finally(() => {setShowMsg(true)})
                      }}
                    >
                      <SaveIcon />
                    </ChipTab>
                  } else { return null }
                case 'kill-sheet':
                  if (
                    (
                      role === 'Support' ||
                      (
                        user?.companyId === userCompanyId &&
                        (role === 'Supervisor' || role === 'Driller' || role === 'Complete') &&
                        user?.scope?.includes('rig')
                      )
                    ) && WellM?.Status !== "completed"
                  ) {
                    return <ChipTab
                      id="saveButton"
                      title="Save"
                      onClick={() => {
                        const now = new Date();
                        if(company && rig) updateKillSheet({
                          wellName: underscoreToSpace(
                            location.pathname.split('/')[2]
                          ),
                          wellSectionName: underscoreToSpace(
                            location.pathname.split('/')[3]
                          ),
                          company: company.id,
                          rig: rig.id
                          }).then(() => {return updateWellMDate({
                            wellName: underscoreToSpace(
                              location.pathname.split('/')[2]
                            ),
                            company: company.id,
                            rig: rig.id,
                            companyId: company.id,
                            rigId: rig.id,
                            updatedDate: now
                          })
                          }).then(() => {return  updateWellSectionDateM({
                            wellName: underscoreToSpace(
                              location.pathname.split('/')[2]
                            ),
                            wellSectionName: underscoreToSpace(
                              location.pathname.split('/')[3]
                            ),
                            company: company.id,
                            rig: rig.id,
                            companyId: company.id,
                            rigId: rig.id,
                            updatedDate: now
                          })
                        })
                        .then(() => {setMsg('Kill Sheet saved!')})
                        .catch(() => {setMsg('Error saving Kill Sheet'); setVariant("error")})
                        .finally(() => {setShowMsg(true)})
                      }}
                    >
                      <SaveIcon />
                    </ChipTab>
                  } else { return null }
                case 'overpull-sheet':
                    if (
                      (
                        role === 'Support' ||
                        (
                          user?.companyId === userCompanyId &&
                          (role === 'Supervisor' || role === 'Driller' || role === 'Complete') &&
                          user?.scope?.includes('rig')
                        )
                      ) && WellM?.Status !== "completed"
                    ) {
                      return <ChipTab
                        id="saveButton"
                        title="Save"
                        onClick={() => {
                          const now = new Date();
                          if(company && rig) updateOverpullCalculator({
                            wellName: underscoreToSpace(
                              location.pathname.split('/')[2]
                            ),
                            wellSectionName: underscoreToSpace(
                              location.pathname.split('/')[3]
                            ),
                            company: company.id,
                            rig: rig.id
                            }).then(() => {return updateWellMDate({
                              wellName: underscoreToSpace(
                                location.pathname.split('/')[2]
                              ),
                              company: company.id,
                              rig: rig.id,
                              companyId: company.id,
                              rigId: rig.id,
                              updatedDate: now
                            })
                            }).then(() => {return  updateWellSectionDateM({
                              wellName: underscoreToSpace(
                                location.pathname.split('/')[2]
                              ),
                              wellSectionName: underscoreToSpace(
                                location.pathname.split('/')[3]
                              ),
                              company: company.id,
                              rig: rig.id,
                              companyId: company.id,
                              rigId: rig.id,
                              updatedDate: now
                            })
                          })
                          .then(() => {setMsg('Overpull Sheet saved!')})
                          .catch((error) => {console.log(error); setMsg('Error saving Overpull Sheet'); setVariant("error")})
                          .finally(() => {setShowMsg(true)})
                        }}
                      >
                        <SaveIcon />
                      </ChipTab>
                    } else { return null }
                case 'broomstick':
                    if (
                      (
                        role === 'Support'  ||
                        (
                          user?.companyId === userCompanyId &&
                          (role === 'Supervisor' || role === 'Driller' || role === 'Complete') &&
                          user?.scope?.includes('rig')
                        )
                      ) && WellM?.Status !== "completed"
                    ) {
                      return <ChipTab
                        id="saveButton"
                        title="Save"
                        onClick={() => {
                          const now = new Date();
                          if(company && rig) updateWellM({
                            wellName: underscoreToSpace(
                              location.pathname.split('/')[2]
                            ),
                            company: company.id,
                            rig: rig.id,
                            updatedDate: now
                          }).then(
                            () =>{ return updateWellSectionM({
                              wellName: underscoreToSpace(
                                location.pathname.split('/')[2]
                                ),
                              wellSectionName: underscoreToSpace(
                                location.pathname.split('/')[3]
                                ),
                              company: company.id,
                              rig: rig.id,
                              updatedDate: now
                            })
                          })
                          .then(() => {setMsg('Torque Chart saved!')})
                          .catch(() => {setMsg('Error saving the well section'); setVariant("error")})
                          .finally(() => {setShowMsg(true)})
                        }}
                      >
                        <SaveIcon />
                      </ChipTab>
                    } else { return null }
                default:
                  return null
              }
            }()}

            <UserInfo user={user} />
            {HEALTH_CHECK ? (
              <div className={isOnline ? 'online' : 'offline'}>
                  <img src={isOnline ? icon_wifi_on : icon_wifi_off} alt="online status" style={{ height: '25px', width: '25px' }} />
                  <img src={icon_wifi_off} alt="loading status" style={{ height: '1px', width: '1px' }} />
              </div>)
            : null}
            <Link
              id="logoutButton"
              title="logout"
              to="/login"
              onClick={() => {
                logout({ variables: { session: localStorage.getItem('session') } })
                // clearCache();
              }}>logout
            </Link>
            <Toast
              variant={variant}
              message={`${msg}`}
              open={showMsg}
            />
          </div>
          <div className="pageBodyContent">
            <div>
              {children}
            </div>
          </div>
        </div>
      </div>
   )
  })

export default Layout
