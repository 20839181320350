import { observer } from 'mobx-react'
import { useMst } from 'app/store'
import TableHeader from './TableHeader';
import SurveyTableRow from './SurveyTableRow';
import '../Survey/Survey.css'


const SurveyTable = observer (() => {
  const {
    store: { WellM },
  } = useMst()

  return (
    <div>
      <div id="survey">
        <TableHeader  />
        {WellM?.Survey ? (WellM?.Survey.rows.map((e) => (
          <SurveyTableRow
            key={e.mdepth}
            mdepth={e.mdepth}
            inclination={e.inclination}
            azimuth={e.azimuth}
          />
        ))) : ('')
        }
      </div>
    </div>
  );
})

export default SurveyTable
