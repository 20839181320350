import { Routes, Route, Navigate } from 'react-router-dom'
import Auth from './Auth'
import Error from './Error'
import DashboardLayout from './Dashboard/DashboardLayout'
import BHA from './BHA'
import Layout from './Layout'
import PipeTally from './PipeTally'
import LinerTally from './LinerTally'
import CasingTally from './CasingTally'
import TripSheetUi from './TripSheet'
import TrendSheetUi from './TrendSheet'
import KillSheetUi from './KillSheet'
import KillChart from './KillChart'
import StateFetcher from './StateFetcher'
import RotatingHours from './RotatingHours'
import Wells from './Wells'
import Well from './Well'
import WellSection from './WellSection'
import { useState, createContext, Dispatch, SetStateAction, useContext } from 'react'
import PipesLibrary from './Library/Tubulars'
import BOP from './BOP'
import OverpullSheet from './OverpullCalculator'
import BroomstickSheet from './Broomstick'
import { UserContext, CompanyContext, RigContext } from 'App'
import { useLazyQuery, gql } from '@apollo/client'
import { UserFields } from 'api/fragments'
import endeavorLogo from './kit/icons/EndeavorLogo.svg'

type LocationState = {
  wellId?: string
  wellSectionId?: string
  wellSectionType?: 'CSG' | 'OH'
  rigId?: string
  rigType?: string
  units?: 'imperial' | 'metric' | 'hybrid'
}

interface RouterContext {
  locationState?: LocationState
  setLocationState?: Dispatch<SetStateAction<LocationState>>
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RouterContext = createContext<RouterContext>({})


const SplashScreen = () => (
    <div
        style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
        }}
    >
        <img src={endeavorLogo} style={{ height: 60 }} alt="website logo" />
    </div>
)


export default function MainContents() {
  const [locationState, setLocationState] = useState<LocationState>({
    wellId: '',
    wellSectionId: '',
    wellSectionType: 'OH',
    rigId: '',
    rigType: '',
    units: 'imperial',
  })

  const { user, setUser } = useContext(UserContext)
  const { setCompany } = useContext(CompanyContext)
  const { setRig } = useContext(RigContext)
  const [ loadAttempted, setLoadAttempted ] = useState(false)

  const [ loadUser, { loading } ] = useLazyQuery(
    gql`
        ${UserFields}
        query me {
        me {
            ...UserFields
        }
    }
    `,
    {
        onCompleted(data) {
          let companyData = undefined;

          try {
            companyData = JSON.parse(data.me.company.imgUrl);
          } catch {}
  
          setUser && setUser({
            id: data.me.id,
            email: data.me.email,
            password: data.me.password,
            firstName: data.me.firstName,
            secondName: data.me.secondName,
            companyId: data.me.companyId,
            rigId: data.me.rigId,
            createdAt: data.me.createdAt,
            permissions: data.me.permissions,
            scope: data.me.scope,
            company: {id: data.me.company.id, name: data.me.company.name, data: companyData},
            rig: data.me.rig
          })

          const storedContext = JSON.parse(window.localStorage.getItem('context') || '{}')
          if(setCompany && storedContext?.company) setCompany(storedContext.company)
          if(setRig && storedContext?.rig) setRig(storedContext.rig)
          },
    }
)


  if (!window.localStorage.getItem('session')) {
    return (
      <Auth />
    )
  }

  if(loading) {
    return (
      <SplashScreen/>
    )
  }

  if(!loadAttempted && !(user)) {
     setLoadAttempted(true);
     loadUser();
  }


  return (
    <RouterContext.Provider value={{ locationState, setLocationState }}>
      <Routes>
        <Route path="/login" element={<Auth />} />
        <Route path="/" element={<Navigate to="/dashboard" />}/>
        <Route path='/error' element= {
          <Layout>
            <Error />
          </Layout>
        } />
        <Route path="/dashboard/*" element={
          <Layout>
            <DashboardLayout />
          </Layout>
        } />
        <Route path="/all-wells/*" element={
          <Layout>
             <Wells />
          </Layout>
        } />
        <Route path="/all-wells/:wellName/*" element={
          <Layout sort='wellName'>
             <StateFetcher>
               <Well startingTabValue={0}/>
             </StateFetcher>
          </Layout>
        } />
        <Route path="/all-wells/:wellName/well-sections-tab/*" element={
          <Layout sort='wellName'>
             <StateFetcher>
               <Well startingTabValue={1}/>
             </StateFetcher>
          </Layout>
        } />
        <Route path="/all-wells/:wellName/kick-tolerance-tab/*" element={
          <Layout sort='wellName'>
             <StateFetcher>
               <Well startingTabValue={2} />
             </StateFetcher>
          </Layout>
        } />
         <Route path="/all-wells/:wellName/survey-tab/*" element={
          <Layout sort='wellName'>
             <StateFetcher>
               <Well startingTabValue={3} />
             </StateFetcher>
          </Layout>
        } />
        <Route path="/all-wells/:wellName/:wellSectionName/*" element={
          <Layout sort='wellSectionName'>
             <StateFetcher>
               <WellSection />
             </StateFetcher>
          </Layout>
        } />
        <Route path='/all-wells/:wellName/:wellSectionName/pipe-tally/*' element={
          <Layout sort='pipe-tally'>
             <StateFetcher>
               <PipeTally />
             </StateFetcher>
          </Layout>
        } />
        <Route path='/all-wells/:wellName/:wellSectionName/liner-tally/*' element={
          <Layout sort='liner-tally'>
             <StateFetcher>
               <LinerTally />
             </StateFetcher>
          </Layout>
        } />
        <Route path='/all-wells/:wellName/:wellSectionName/casing-tally/*' element={
          <Layout  sort='casing-tally'>
             <StateFetcher>
               <CasingTally />
             </StateFetcher>
          </Layout>
        } />
        <Route path="/all-wells/:wellName/:wellSectionName/bha/*" element={
          <Layout sort='bha'>
             <StateFetcher>
               <BHA />
             </StateFetcher>
          </Layout>
        } />
        <Route path="/all-wells/:wellName/:wellSectionName/trip-sheet/*" element={
          <Layout sort='trip-sheet'>
             <StateFetcher>
               <TripSheetUi />
             </StateFetcher>
          </Layout>
        } />
        <Route path="/all-wells/:wellName/:wellSectionName/trend-sheet/*" element={
          <Layout sort='trend-sheet'>
             <StateFetcher>
               <TrendSheetUi />
             </StateFetcher>
          </Layout>
        } />
        <Route path="/all-wells/:wellName/:wellSectionName/kill-sheet/*" element={
          <Layout sort='kill-sheet'>
             <StateFetcher>
               <KillSheetUi />
             </StateFetcher>
          </Layout>
        } />
        <Route path="/all-wells/:wellName/:wellSectionName/kill-graph/*" element={
          <Layout>
             <StateFetcher>
               <KillChart />
             </StateFetcher>
          </Layout>
        } />
          <Route path="/all-wells/:wellName/:wellSectionName/overpull-sheet/*" element={
          <Layout sort='overpull-sheet'>
             <StateFetcher>
               <OverpullSheet />
             </StateFetcher>
          </Layout> 
        } />
         <Route path="/all-wells/:wellName/:wellSectionName/broomstick/*" element={
          <Layout sort='broomstick'>
             <StateFetcher>
               <BroomstickSheet />
             </StateFetcher>
          </Layout>
        } />
        <Route path="/all-wells/:wellName/:wellSectionName/bop/*" element={
          <Layout sort='bop'>
             <StateFetcher>
               <BOP />
             </StateFetcher>
          </Layout>
        } />
        <Route path="/all-wells/:wellName/:wellSectionName/liner/*" element={
          <Layout>
             <StateFetcher>
               <LinerTally />
             </StateFetcher>
          </Layout>
        } />
        <Route path="/library/tubulars/*" element={
          <Layout>
             <PipesLibrary />
          </Layout>
        } />
        <Route path="/RotatingHours/*" element={
          <Layout>
             <RotatingHours />
          </Layout>
        } />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </RouterContext.Provider>
  )
}
