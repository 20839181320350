import { useContext, useEffect, useState } from 'react'
import { UserContext, CompanyContext } from 'App'
import { useQuery, gql } from '@apollo/client'
import iconCaretDown from '../../../assets/images/icons/icon_caretDown.svg'

const Company = () => {
    const { user } = useContext(UserContext)
    const { company, setCompany } = useContext(CompanyContext)
    const [scope, setScope] = useState('')

    const { data, loading } = useQuery(
        gql`
        query company {
            company {
                id
                name
                imgUrl
            }
        }
        `,
        {
            fetchPolicy: "no-cache"
        }
    )

    useEffect(() => {
        const operator = ['rig']
        const shorebased = ['rig', 'company']
        const support = ['rig', 'company', 'global']

            switch (JSON.stringify(user?.scope)) {
            case JSON.stringify(operator):
                setScope('operator')
                break
            case JSON.stringify(shorebased):
                setScope('shorebased')
                break
            case JSON.stringify(support):
                setScope('support')
                break
            default:
                setScope('operator')
                break
        }
    }, [user?.scope])


    function handleChange(event) {
        const thisCompany = data.company.find((c) => c.id === event.target.value)
        const context = JSON.parse(window.localStorage.getItem('context'))
        context.company.id = thisCompany.id
        context.company.name = thisCompany.name
        try{
            context.company.data = JSON.parse(thisCompany.imgUrl)
        } catch {
            context.company.data = undefined;
        }
        
        window.localStorage.setItem('context', JSON.stringify(context))

        setCompany && setCompany(context.company)
    }

    return (
        <>
            {!loading && scope === 'support' ? (
                <>
                    <img 
                        alt="Company"
                        src={iconCaretDown}
                        width="18px" height="18px"
                        style={{ userSelect: "none" }}
                        className="doNotPrint"
                    />
                    <select
                        id="currentCompany"
                        onChange={handleChange}
                        value={company?.id}
                        title="Select a Company"
                        className="dashboardSelect"
                    >
                      {data?.company.map((comp) => (
                          <option key={comp.id} value={comp.id}>
                              {comp.name}
                          </option>
                      ))}
                    </select>
                </>
            ) : (
                <>
                    {company?.name}
                </>
            )}
        </>
    );
}

export default Company
