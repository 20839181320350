import {
  SetStateAction,
  Dispatch,
  HTMLProps,
  useCallback,
  useState,
} from 'react'
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
  SortableHandle,
} from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'
import { ArrayElement } from 'utils/helpers'
import { TrashActionButton } from '../kit/IconButtons'
import { ReactComponent as HandleIcon } from '../kit/icons/drag-handle.svg'
import { produce } from 'immer'

const IntermediateTableHead = () => (
  <thead>
    <tr style={{width: '100%'}}>
      <th style={{ borderRight: 'none', width: '60px' }}></th>
      <th style={{ width: '130px', borderLeft: 'none' }}>
        Serial
        <br />
        No.
      </th>
      <th style={{ width: '130px', borderRight: 'none' }}>
        Single
        <br />
        length
      </th>
      <th style={{ width: '130px', borderRight: 'none' }}>Tool?</th>
      <th style={{ borderLeft: 'none', width: '20px' }}></th>
    </tr>
  </thead>
)

const Handle = SortableHandle(() => {
  return (
    <td style={{ borderRight: 'none', width: 60 }} className={'drag-handle'}>
      <HandleIcon />
    </td>
  )
})

const SortableTableRow = SortableElement<ArrayElement<IntermediatePipes> & {
  className?: string
  drag?: boolean
  onDelete?: () => void
}>(
  ({
    length,
    serialNumber,
    toolCheckbox,
    className,
    drag,
    onDelete,
  }: ArrayElement<IntermediatePipes> & {
    className?: string
    drag?: boolean
    onDelete?: () => void
  }) => {
    const [show, setShow] = useState(false)
    return (
      <tr
        id="draggable-tr"
        className={className}
        style={{ zIndex: 1000, width: '20px' }}
        onMouseOver={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        <Handle />
        <td style={{ borderLeft: 'none', width: '130px' }} className={'ip_serial'}>
          {serialNumber}
        </td>
        <td style={{ borderRight: 'none', width: '130px' }} className={'ip_length'}>
          {Number(length).toFixed(3)}
        </td>
        {toolCheckbox === true ? (
          <td style={{ borderRight: 'none', width: '130px' }} className={'ip_tool'}>
          Tool
        </td>
        ) : (<td style={{ borderRight: 'none', width: '130px' }} className={'ip_tool'}></td>) 
      }
        <td
          style={{ border: 'none', backgroundColor: 'transparent', width: '30px' }}
          className={'ip_delete'}
        >
          {!drag && show ? (
            <TrashActionButton
              style={{ display: 'flow', overflow: 'visible' }}
              onClick={onDelete}
            />
          ) : null}
        </td>
      </tr>
    )
  }
)

const SortableTableBody = SortableContainer<{
  list: IntermediatePipes
  drag: boolean
  data: IntermediatePipes
  setData: Dispatch<SetStateAction<IntermediatePipes>>
}>(
  ({
    list,
    drag,
    data,
    setData,
  }: {
    list: IntermediatePipes
    drag: boolean
    data: IntermediatePipes
    setData: Dispatch<SetStateAction<IntermediatePipes>>
  }) => {
    return (
      <tbody style={{ maxHeight: 280 }}>
        {list.map((pipe, i) => (
          <SortableTableRow
            drag={drag}
            index={i}
            key={`intermediate-pipe-${pipe.serialNumber}`}
            {...pipe}
            onDelete={() => {
              setData(
                produce(data, (draft) => {
                  draft.splice(i, 1)
                })
              )
            }}
          />
        ))}
      </tbody>
    )
  }
)

const IntermediatePipeList = ({
  data,
  setData,
  ...props
}: IntermediatePipeListProps) => {
  const [drag, setDrag] = useState(false)
  const handleSortEnd = useCallback<SortEndHandler>(
    ({ oldIndex, newIndex }) => {
      setData((prev) => arrayMoveImmutable(prev, oldIndex, newIndex))
      document.body.classList.remove('grabbing')
      setDrag(false)
    },
    [setData, setDrag]
  )
  return (
    <div style={{ width: '500px'}}>
    <table
      style={{ width: '100%', marginLeft: 0, marginRight: 0, borderCollapse: 'collapse' }}
      className="pipe-list-table"
      {...props}
    >
      <IntermediateTableHead />
      <SortableTableBody
        axis="y"
        lockAxis="y"
        lockToContainerEdges={true}
        lockOffset={['30%', '50%']}
        list={data}
        drag={drag}
        onSortEnd={handleSortEnd}
        helperClass="drag-helper"
        onSortStart={() => {
          setDrag(true)
          document.body.classList.add('grabbing')
        }}
        useDragHandle
        data={data}
        setData={setData}
      />
    </table>
    </div>
  )
}

export type IntermediatePipes = { length: string; serialNumber: string; toolCheckbox: boolean }[]

type IntermediatePipeListProps = {
  data: IntermediatePipes
  setData: Dispatch<SetStateAction<IntermediatePipes>>
} & HTMLProps<HTMLTableElement>

export default IntermediatePipeList
