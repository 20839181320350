import Broomstick from './Broomstick'
import { SectionTabs } from '../WellSection/SectionTabs'
import './broomstick.css'

const BroomstickSheet = () => {

    return (
        <div className="broomstick-page">
            <SectionTabs />
            <h1 style={{ color: '#fff', marginLeft: '8px', fontSize: '28pt' }}>T&D</h1>
            <Broomstick />
        </div>
    )
}

export default BroomstickSheet