//import {  useContext } from 'react'
//import { RouterContext } from '../Router'
import { observer } from 'mobx-react'
import TdChart from './BroomstickChart'
import BroomstickTable from './BroomstickTable'
//import { useMst } from 'app/store'
//import { div, getLabel} from '../../../utils/helpers'
//import { getStatistic, conversion, numberFormatter } from 'utils/helpers'
//import { unitConversion } from '../../../utils/helpers'
//import warning from '../../../assets/images/icons/icon_warning.svg'

const BroomstickChart = observer(() => {

    return (
        <div>
            <div className="" style={{ width: '100%' }}>
                <div id="dashboardChartROPContainer" className="uiContainerTitle">
                    <div style={{  width: '100%' }}>
                    <BroomstickTable/>
                    <TdChart />
                    </div>
                </div>
            </div>
        </div>
       

    )
})

export default BroomstickChart