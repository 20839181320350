// import { Link } from 'react-router-dom'
import { RESET_PASSWORD_LINK } from '../../constants/common'

const Help = () => {
  return (
    <div className="uiContainer doNotPrint">
      <div className="uiContainerTitle">Help</div>
      <a href={RESET_PASSWORD_LINK} className="uiContainerSecondaryLink">Support Requests</a>
    </div>
  );
}

export default Help
