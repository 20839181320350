import Rig from './Rig'
import Company from './Company'

const Summary  = ({
    queryData,
    queryLoading,
}) => {

    return (
        <div className="uiContainer uiContainerShallow">
        <div className="uiContainerTitle">Summary</div>
            <div className="uiContainerText">
                <span className="uiContainerLinkLabel">Company: </span>
                <span className="uiContainerLinkText"><Company/></span>
            </div>
            <div className="uiContainerText">
                <span className="uiContainerLinkLabel">Current Rig: </span>
                <span className="uiContainerLinkText"><Rig/></span>
            </div>
            {!queryLoading && queryData.getWellSections
            .toSorted((a, b) => {
                const aUpdatedDate = (typeof a.data === 'string' ? JSON.parse(a.data) : a.data).UpdatedAt;
                const bUpdatedDate = (typeof b.data === 'string' ? JSON.parse(b.data) : b.data).UpdatedAt;
                const aComparisonDate = aUpdatedDate ? aUpdatedDate : new Date(a.updatedAt).getTime();
                const bComparisonDate = bUpdatedDate ? bUpdatedDate : new Date(b.updatedAt).getTime();
                return bComparisonDate - aComparisonDate;

            }).slice(0, 1).map(({ id, name, type, status, createdAt, updatedAt, well, data: wellSectionData }) => {
             
            const parsedWellData = JSON.parse(well.data);
            const wellDataStatus = parsedWellData?.Status;
            
             return (
                <div key={id}>
                <div className="uiContainerText">
                    <span className="uiContainerLinkLabel">Current Well: </span>
                    <div className="uiContainerLinkText">
                      <span>
                        {well.name.length > 50 ? well.name.substring(0, 50) + '...' : well.name}
                      </span>
                      <span className={well.status === 'complete' || wellDataStatus === 'completed' ? 'dashboardStatusComplete' : 'dashboardStatusInProgress'}/>
                    </div>
                </div>
                <div className="uiContainerText">
                    <span className="uiContainerLinkLabel">Current Section: </span>
                    <div className="uiContainerLinkText">
                      <span>{
                          name.length > 50 ? name.substring(0, 50) + '...' : name
                      }</span>
                    </div>
                </div>
            </div>

             )
            
            })}
        </div>
    );
}

export default Summary
