import { Instance, types, SnapshotIn } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import Tool from "./Tool"

export type WellSectionMInstance = Instance<typeof WellSectionM>
export type WellSectionMSetAttributes = SnapshotIn<typeof WellSectionM>

const WellSectionM = types
  .model('WellSectionM', {
    id: types.optional(types.identifier, uuid),
    mudBase: types.optional(types.string, 'oil'),
    mudWeight: types.optional(types.number, 0),
    yieldPoint: types.optional(types.number, 0),
    outD: types.optional(types.number, 0),
    inD: types.optional(types.number, 0),
    bitSize: types.optional(types.number, 0),
    maasp: types.optional(types.number, 0),
    cp: types.optional(types.number, 0),
    UpdatedAt: types.optional(types.number, new Date().getTime()),
    tools: types.optional(types.array(Tool), []),
    tripSpeed: types.optional(types.number, 0),
    rotarySpeed: types.optional(types.number, 0),
    maxFf: types.optional(types.number, 0),
    minFf: types.optional(types.number, 0),
    ffInc: types.optional(types.number, 0),
  })

  .views((self) => ({
  }))
  .actions((self) => {
    return {
      createToolRow(id: string, name: string, depth: number) {
        self.tools.push(Tool.create({id: id, toolName: name, toolDepth: depth}))
      },
      removeToolRow(idx : number) {
        self.tools.splice(idx, 1);
      },
      updateToolRowName(idx: number, name: string) {
        self.tools[idx].updateName(name)
      },
      updateToolRowDepth(idx: number, depth: number) {
        self.tools[idx].updateDepth(depth)
      },
      updateWellSectionData(id: string, field: any, fieldType: string, value: string) {
        const updateFieldType: string = fieldType
        const updateField: typeof updateFieldType = field
        const updateValue = value

        if (updateField === 'mudBase') {
          self.mudBase = updateValue
        } else if (updateField === 'mudWeight') {
          self.mudWeight = parseFloat(updateValue)
        } else if (updateField === 'yieldPoint') {
          self.yieldPoint = parseFloat(updateValue)
        } else if (updateField === 'outD') {
          self.outD = parseFloat(updateValue)
        } else if (updateField === 'inD') {
          self.inD = parseFloat(updateValue)
        } else if (updateField === 'bitSize') {
          self.bitSize = parseFloat(updateValue)
        } else if (updateField === 'maasp') {
          self.maasp = parseFloat(updateValue)
        } else if (updateField === 'cp') {
          self.cp = parseFloat(updateValue)
        } else if (updateField === 'UpdatedAt') {
          self.UpdatedAt = new Date(updateValue).getTime()
        } else if (updateField === 'tripSpeed') {
          self.tripSpeed = parseFloat(updateValue)
        } else if (updateField === 'rotarySpeed') {
          self.rotarySpeed = parseFloat(updateValue)
        }else if (updateField === 'maxFf') {
          self.maxFf = parseFloat(updateValue)
        }else if (updateField === 'minFf') {
          self.minFf = parseFloat(updateValue)
        }else if (updateField === 'ffInc') {
          self.ffInc = parseFloat(updateValue)
        }
      },
    }
  })

export default WellSectionM
