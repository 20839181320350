import {Instance,types,SnapshotIn} from "mobx-state-tree"

export type BroomstickInstance = Instance<typeof Broomstick>;
export type OverpullCalculatorSetAttributes = SnapshotIn<typeof Broomstick>;

export type CreateOverpullCalculatortInput = {
  id: string;
}

export const Broomstick = types
  .model("Broomsctick", {
    // id: types.optional(types.identifier, uuid),
  })
  .views((self) => ({
   
  }))
  .actions((self) => {
    return {
      updateBroomstick(id: string, field: any, fieldType: string, value: string) {
          // const updateFieldType: string = fieldType
          // const updateField: typeof updateFieldType = field
          // const updateValue = value

          // if (updateField === 'id') {
          //     self.id = updateValue
          // } else if (updateField === 'safetyFactor') {
          //     self.safetyFactor = parseFloat(updateValue)
          // } else if (updateField === 'steelDensity') {
          //     self.steelDensity = parseFloat(updateValue)
          // }
      },

    }
  })

export default Broomstick