import { useContext } from 'react'
import { RouterContext } from '../Router'
import { observer } from 'mobx-react'
import { useMst } from 'app/store'
import Chart from 'react-apexcharts'
import { unitConversion } from '../../../utils/helpers'

const TdChart = observer(() => {
    const {
        store: { TrendSheet },
    } = useMst()
    const { locationState } = useContext(RouterContext)

    
    const torqueActualPoints = [];
    const dragActualPoints = [];
    
      if(TrendSheet && TrendSheet.rows) {
        for(const trendsheetRow of TrendSheet.rows) {
            torqueActualPoints.push({
            x: trendsheetRow?.torque,
            y: unitConversion('lengthMedium', locationState?.units, "out", trendsheetRow?.depth, 10)
           })
           //console.log(dragActualPoints);
        }
      }

      if(TrendSheet && TrendSheet.rows) {
        for(const trendsheetRow of TrendSheet.rows) {
           dragActualPoints.push({
            x: trendsheetRow?.wob,
            y: unitConversion('lengthMedium', locationState?.units, "out", trendsheetRow?.depth, 10)
           })
           //console.log(dragActualPoints);
        }
      }


      function getMaxDepth() {
        if(TrendSheet && TrendSheet.rows) {
            if (TrendSheet.rows.length > 0)  {
                const lastRow = TrendSheet.rows[TrendSheet.rows.length -1]
                return  lastRow?.depth || 0
            }      
        }
      }
      //console.log(unitConversion('lengthMedium', locationState?.units, "out", getMaxDepth(), 10).toFixed(0))

    // const dragActualPoints = [
    //     {
    //       x: 30,
    //       y: 100
    //     },
    //     {
    //       x: 31,
    //       y: 80
    //     },
    //     {
    //       x: 40,
    //       y: 60
    //     },
    //     {
    //       x: 45,
    //       y: 40
    //     }
    //   ]

    const torqueChart = {
        series: [
            {
              name: "TORQUE/WOB",
              type: 'scatter',
              data: torqueActualPoints
            },
            {
              name: "SIDPP",
              type: 'line',
              data: [
                {
                  x: 30,
                  y: 0
                },
                {
                  x: 31,
                  y: 13500
                },
                {
                  x: 35,
                  y: 14500
                },
                {
                  x: 66,
                  y:  unitConversion('lengthMedium', locationState?.units, "out", getMaxDepth(),10)
                }
              ]
            },
            {
                name: "SIDPP",
                type: 'line',
                data: [
                  {
                    x: 30,
                    y: 0
                  },
                  {
                    x: 30,
                    y: 1260
                  },
                  {
                    x: 28,
                    y: 14380
                  },
                  {
                    x: 20,
                    y:  unitConversion('lengthMedium', locationState?.units, "out", getMaxDepth(),10)
                  }
                ]
              },
        ],
        options: {
            chart: {
                background: '#fff',
                height: 450,
                type: 'line',
                animations: {
                        enabled: true
                },
            },
            legend: {
                position: 'top',
                labels: {
                    style: {
                        colors: 'Gray',
                    }
                },
            },

            xaxis: {
                type: 'category',
                min: 0,
                max: 80,
                labels: {
                    style: {
                        colors: 'Gray',
                    }
                }
            },
            markers: {
                size: [6, 0, 0]
            },
            yaxis: {
             reversed: true,
             labels: {
                style: {
                    colors: 'Gray',
                }
              },
             min: 0,
             max: unitConversion('lengthMedium', locationState?.units, "out", getMaxDepth(),10),
            }

          }

    }
    
    return (
        <div>
            <div>
                <h2>Torque Chart</h2>
                <Chart
                    id={'chart'}
                    options={torqueChart.options}
                    series={torqueChart.series}
                    width="700px"
                    height="450px"
                    type='line'
                />
            </div>
        </div>
    );
})

export default TdChart
