import { types } from 'mobx-state-tree'

const SurveyRow = types
  .model('SurveyRow', {
    mdepth: types.union(types.number, types.null),
    azimuth: types.union(types.number, types.null),
    inclination: types.union(types.number, types.null),
  })
  .actions((self) => ({

  }))

export default SurveyRow
