import { useContext, MouseEventHandler } from 'react';
import { RouterContext } from '../Router'
import { useMst } from 'app/store'
import { getLabel } from '../../../utils/helpers'
import { AddActionButton } from '../kit/IconButtons'
import { observer } from 'mobx-react'

const TableHeader = observer(() => {
  const {
    store: { WellM },
  } = useMst()
  const { locationState } = useContext(RouterContext)

  const handleAdd: MouseEventHandler<HTMLButtonElement> = () => {
    if(WellM && WellM?.Survey.rows) {
      const lastRow = WellM?.Survey.rows[WellM?.Survey.rows.length - 1]
      if(lastRow?.mdepth !== null) {
        WellM?.Survey.createEmptyRow();
      }
    }
}

  return (
    <div className="survey-row-head">
      <div className="survey-head-item">
        <div
          style={{
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'left',
          }}
        >
          <AddActionButton onClick={handleAdd} />
        </div>
      </div>
      <div className="survey-head-item">
        MD ({getLabel('lengthMedium', locationState)})
      </div>
      <div className="survey-head-item">
        Inclination (deg)
      </div>
      <div className="survey-head-item">
        Azimuth (deg)
      </div>
    </div>
  );
})

export default TableHeader