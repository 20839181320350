import { useQuery, gql, useMutation } from '@apollo/client'
import { produce } from 'immer'
import { useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Dialog from './kit/Dialog'
import Input from './kit/Input'
import { Button, ImportButton } from './kit/SmallWidthButton'
import { RadioButton } from './kit/RadioButton'
import { spaceToUnderscore, underscoreToSpace, removeNonAlphaNumericExceptSpacePeriodHyphen } from 'utils/helpers'
import { withStyles } from '@mui/styles'
import Metrics from './Well/Metrics'
import Units from './Well/Units'
import DataTable from './Well/DataTable'
import Clock from './Well/Clock'
import Badges from './Well/Badges'
import LastSection from './Well/LastSection'
import KickCalculatorTool from './Tools/KickCalculatorTool'
import Survey from './Survey'
import { CompanyContext, RigContext } from 'App'
import './Wells.css'
import './PipeTally/pipeTally.css'

const Head = () => (
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Date</th>
    </tr>
</thead>
)
const CreateWellSection = ({ refetch, open, onClose, wellName, companyId }) => {
  const [form, setForm] = useState({ name: '', type: '', base: 'new', copy: '' })
  const { company } = useContext(CompanyContext)
  const { rig } = useContext(RigContext)

  const { data, loading } = useQuery(
    gql`
      query getWellSections($companyId: String!, $rigId: String!, $take: String!, $name: String!) {
        getWellSections(
          companyId: $companyId,
          rigId: $rigId,
          take: $take,
          name: $name,
        ) {
          id
          name
          type
          updatedAt
          createdAt
        }
      }
    `,
    {
      variables: {
        name: underscoreToSpace(wellName),
        companyId: company?.id,
        rigId: rig?.id,
        take: '500',
      },
    }
  )

  const [createWellSection] = useMutation(
    gql`
      mutation createWellSection(
        $name: String!
        $type: String!
        $wellName: String!
        $companyId: String!
        $rigId: String!
      ) {
        createWellSection(
            name: $name
            type: $type
            rig: $rigId
            company: $companyId
            wellName: $wellName
        ) {
          id
        }
      }
    `,
        {
          variables: {
            rigId: JSON.parse(localStorage.getItem('context')).rig.id,
        },
      onCompleted() {
        refetch()
        setForm({ name: '', type: '' })
        onClose()
      },
    }
  )

  const [copyWellSection] = useMutation(
    gql`
      mutation copyWellSection(
        $name: String!
        $copy: String!
        $type: String!
        $wellName: String!
        $companyId: String!
        $rigId: String!
      ) {
        copyWellSection(
            name: $name
            copy: $copy
            type: $type
            rig: $rigId
            company: $companyId
            wellName: $wellName
        ) {
          id
        }
      }
    `,
        {
          variables: {
            rigId: JSON.parse(localStorage.getItem('context')).rig.id,
        },
      onCompleted() {
        refetch()
        setForm({ name: '', type: '' })
        onClose()
      },
    }
  )

  const handleName = (e) => {
    setForm(
      produce(form, (draft) => {
        draft.name = sanitizeInput(e.target.value)
      })
    )
  }

  const handleType = {
    openHole() {
      setForm(
        produce(form, (draft) => {
          if (draft.type !== 'OH') draft.type = 'OH'
        })
      )
    },
    casing() {
      setForm(
        produce(form, (draft) => {
          if (draft.type !== 'CSG') draft.type = 'CSG'
        })
      )
    },
  }

  const handleBase = {
    new() {
      setForm(
        produce(form, (draft) => {
          if (draft.base !== 'new') draft.base = 'new'
        })
      )
    },
    copy() {
      setForm(
        produce(form, (draft) => {
          if (draft.base !== 'copy') draft.base = 'copy'
        })
      )
    },
  }

  function sanitizeInput(str) {
    return removeNonAlphaNumericExceptSpacePeriodHyphen(str)
  }

  const hasWellSectionData = !loading && data?.getWellSections?.length > 0;

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="createWellSectionWrapper">
        <h3 className="createWellSectionHeading">Create well section</h3>

        {hasWellSectionData?
          <>
            <h4 className="createWellSectionSubheading">Create New or Copy Existing</h4>
            <div className="createWellSectionPickerContainer">
              <div className="direction-picker">
                <RadioButton
                  id="newWellSectionRadioButton"
                  checked={form.base === 'new'}
                  onChange={() => {handleBase.new()}}
                />
                <label className="createWellSectionPickerLabel" htmlFor="newWellSectionRadioButton">New Well Section</label>
              </div>

              <div className="direction-picker">
                <RadioButton
                  id="copyExistingSectionRadioButton"
                  checked={form.base === 'copy'}
                  onChange={() => {handleBase.copy()}}
                />
                <label className="createWellSectionPickerLabel" htmlFor="copyExistingSectionRadioButton">Copy Existing Section</label>
              </div>
            </div>

            <div className={form.base === 'copy' ? 'createWellSectionSelectExistingContainer' : 'invisible'}>
              <select
                id="existingWellSectionSelect"
                size={5}
                className="selectWellSection"
                onChange={(e) => {
                  setForm(
                    produce(form, (draft) => {
                      draft.copy = e.target.value
                    })
                  )
                }}
              >
                {!loading && data?.getWellSections.slice().sort(function(a, b) {
                  return a.name.localeCompare(b.name); }
                ).map(({ id, name, type, updatedAt }) => (
                  <option
                    key={id}
                    value={id}
                    name={name}
                    className="selectWellSectionItem"
                  >
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </> : null
        }
      <h4 className="createWellSectionSubheading">Section Name</h4>
        <div>
          <Input
            id="wellSectionName"
            title=""
            onChange={handleName}
            onKeyUp={(e) => {
              e.target.value = sanitizeInput(e.target.value)
            }}
            className="inputModalField"
            labelClassName="placeholder"
            wrapperClassName="field"
            style={{ width: 400, marginTop: 0 }}
          />
          <div className="createWellSectionFinePrint">* Alphanumeric, period, hyphen and spaces only</div>
        </div>

        <h4 className="createWellSectionSubheading">Section Type</h4>
        <div className="createWellSectionPickerContainer">
          <div className="direction-picker" style={{ paddingLeft: 10 }} onClick={handleType.openHole}>
            <RadioButton
              id="select-before"
              checked={form.type === 'OH'}
              onChange={() => {}}
            />
            <label className="createWellSectionPickerLabel" htmlFor="select-before">Drilling Operations</label>
          </div>
          <div className="direction-picker" onClick={handleType.casing}>
            <RadioButton
              id="select-after"
              checked={form.type === 'CSG'}
              onChange={() => {}}
            />
            <label className="createWellSectionPickerLabel" htmlFor="select-after">Run Casing/Liner</label>
          </div>
        </div>
        <div className="createWellSectionConfirmBtnWrapper">
          <Button
            id="btnCreateWellSection"
            small
            disabled={!form.name || form.type.length === 0 || form.base.length === 0 || (form.base === 'copy' && document.getElementById('existingWellSectionSelect').value === '')}
            onClick={() => {
              form.base === 'copy' ?
              copyWellSection({
                variables: { ...form, wellName, companyId },
              })
              :
              createWellSection({
                variables: { ...form, wellName, companyId },
              })
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

const WellSections = ({startingTabValue}) => {
  const [open, setOpen] = useState(false)
  const router = useNavigate()
  const { wellName } = useParams()
  const { company } = useContext(CompanyContext)
  const { rig } = useContext(RigContext)
  const { data, loading, refetch } = useQuery(
    gql`
      query getWellSections($companyId: String!, $rigId: String!, $take: String!, $name: String!) {
        getWellSections(
          companyId: $companyId,
          rigId: $rigId,
          take: $take,
          name: $name,
        ) {
          id
          name
          type
          updatedAt
          createdAt
          data
        }
      }
    `,
    {
      variables: {
        name: underscoreToSpace(wellName),
        companyId: company?.id,
        rigId: rig?.id,
        take: '500',
      },
    }
  )
    const StyledTab = withStyles({
    root: {
      textTransform: 'capitalize',
      '&.Mui-selected > span': { color: '#fff !important', cursor: 'default' },
      '&.MuiTab-textColorInherit > span:hover': { color: '#5c85f5 !important' },
      '&.Mui-selected > span:hover': { color: '#fff !important' },
      padding: '5px',
      minWidth: 0,
      marginRight: 28,
      marginBottom: '0px',
      fontSize: '14px',
      color: 'silver !important',
    },
  })(Tab)

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div {...other}>
        {value === index && <div>{children}</div>}
      </div>
    );
  }
  const handleChange = (event, newValue) => {
    switch(newValue) {
      case 1:
        router(`/all-wells/${spaceToUnderscore(wellName)}/well-sections-tab/`);
        break;
      case 2:
        router(`/all-wells/${spaceToUnderscore(wellName)}/kick-tolerance-tab/`)
        break;
      case 3:
          router(`/all-wells/${spaceToUnderscore(wellName)}/survey-tab/`)
          break;
      default:
        router(`/all-wells/${spaceToUnderscore(wellName)}/`)
        break;
    }
  };
  return (
    <div>
      <div id="wellTabs" style={{ display: 'flex', flexDirection: 'row' }}>
        <Tabs value={startingTabValue} onChange={handleChange}>
          <StyledTab label="Summary" />
          <StyledTab label="Well Sections" />
          <StyledTab label="Kick Tolerance" />
          <StyledTab label="Survey" />
        </Tabs>
      </div>
      <TabPanel value={startingTabValue} index={0}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Badges />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className="wellInfoTimeClockWrapper">
                  <div className="timeclock wellInfoTimeClock"><Clock /></div>
                  <div id="timeclock" className="timeclock"></div>
                </div>
                <LastSection />
              </div>
            </div>
          <Metrics />
          <div className="unitSelectionWrapper">
            <div className="unitSelectionContainer">
              <div id="unitConversions" className="unitSelectionPanel">
                <Units />
              </div>
            </div>
          </div>
          <DataTable host="well" well={wellName} />
      </TabPanel>
      <TabPanel value={startingTabValue} index={1}>
        <CreateWellSection
          open={open}
          onClose={() => {
            setOpen(false)
          }}
          refetch={refetch}
          wellName={underscoreToSpace(wellName)}
          companyId={JSON.parse(localStorage.getItem('context')).company.id}
        />
        <div className="createWellSectionBtnWrapper doNotPrint">
          <ImportButton
            title="Create well section"
            onClick={() => setOpen(true)}
          />
        </div>
        <div className="wellTableContainer">
          <table className="wellTable wellSectionsTable">
              <Head />
              <tbody>
                {!loading &&
                  data?.getWellSections
                  .toSorted((a, b) => {
                    const aUpdatedDate = (typeof a.data === 'string' ? JSON.parse(a.data) : a.data).UpdatedAt;
                    const bUpdatedDate = (typeof b.data === 'string' ? JSON.parse(b.data) : b.data).UpdatedAt;
                    const aComparisonDate = aUpdatedDate ? aUpdatedDate : new Date(a.updatedAt).getTime();
                    const bComparisonDate = bUpdatedDate ? bUpdatedDate : new Date(b.updatedAt).getTime();
                    return bComparisonDate - aComparisonDate;
                  }).map(({ id, name, type, updatedAt, data: wellSectionData }) => {
                    const parsedData = (typeof wellSectionData === 'string' ? JSON.parse(wellSectionData) : wellSectionData);
                    const setDate = (parsedData.UpdatedAt ?  new Date(parsedData.UpdatedAt) : new Date(updatedAt));
                    const wellSectionUpdated = setDate.toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'});
                    const wellSectionUpdatedTime = setDate.toLocaleTimeString()
                    
                    return (
                    <tr
                      key={id}
                      onClick={() => {
                        router(`/all-wells/${spaceToUnderscore(wellName)}/${spaceToUnderscore(name)}`)
                      }}
                    >
                      <td>
                        {name}
                      </td>
                      <td>
                        {type === 'CSG' ? 'Casing' : 'Open hole'}
                      </td>
                      <td>
                        {wellSectionUpdated}
                        <span style={{ marginLeft: '9px', color: '#67687e' }}>
                          {wellSectionUpdatedTime}
                        </span>
                      </td>
                    </tr>
                )})}
              </tbody>
          </table>
        </div>
      </TabPanel>
      <TabPanel value={startingTabValue} index={2}>
         <KickCalculatorTool />
      </TabPanel>
      <TabPanel value={startingTabValue} index={3}>
         <Survey />
      </TabPanel>
    </div>
  )
}

export default WellSections
