import { useContext} from 'react';
import { observer } from 'mobx-react'
import { useMst } from 'app/store'
import {
  TrashActionButton,
} from '../kit/IconButtons'
import { RouterContext } from '../Router'
import { unitConversion } from '../../../utils/helpers'

const SurveyTableRow = observer((e: {mdepth: number | null, inclination: number | null, azimuth: number | null }) => {

const {
    store: { WellM },
} = useMst()

const { locationState } = useContext(RouterContext)
    
const checkDuplicates = (mdepth: number) => {
  return WellM?.Survey?.rows ? (WellM?.Survey.rows.find((row) => row.mdepth === mdepth) ? true : false) : false;
} 

const handleSort = (mdepth: number) => {
  if(WellM?.Survey?.rows) {
    const currentRow = WellM?.Survey.rows.find((r) => r.mdepth === mdepth);
    if(currentRow?.mdepth !== null && currentRow?.inclination !== null && currentRow?.azimuth !== null) {
      WellM?.Survey.sortSurveyRows();
    }
  }
}

return (
    <div
      className="survey-row">
      <div className="survey-item">
      <TrashActionButton
        id={e.mdepth?.toString() }
        onClick={() => {
        WellM?.Survey.deleteSurveyRow(e.mdepth)
      }}/>  
       
      </div>
      <div className="survey-item">
        <input
          id={`${e.mdepth?.toString()}-mdepth`}
          name="mdepth"
          className="survey-cell-input"
          type="number"
          title="Measuered Depth"
          min="0"
          defaultValue={e.mdepth === null ? "" : unitConversion('lengthMedium', locationState?.units, 'out', e.mdepth, 15).toFixed(2)}
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          onBlur={((event) => {
            if(event.target.value === '') return;
            const newDepth = unitConversion('lengthMedium', locationState?.units, 'in', parseFloat(event.target.value), 15);
            
            if(newDepth < 0 || checkDuplicates(newDepth)) {
              event.target.value = e.mdepth !== null ? unitConversion('lengthMedium', locationState?.units, 'out', e.mdepth, 15).toString() : '';
            } else {
              WellM?.Survey.updateSurveyCell(e.mdepth, 'mdepth', 'number', newDepth.toString());
              handleSort(newDepth);
            }
          })}
        />
      </div> 
      <div className="survey-item">
        <input
            id={`${e.inclination?.toString()}-inclination`}
            name="inclination"
            type="number"
            min="0"
            title="Inclination"
            value={e.inclination === null ? "" : e.inclination}
            onFocus={e => e.target.select()}
            onClick={(e) => {
                e.stopPropagation()
            }}
            className="survey-cell-input"
            onChange={((event) => {
              WellM?.Survey.updateSurveyCell(e.mdepth, 'inclination', 'number', event.target.value);
              if(e.mdepth !== null)
              handleSort(e.mdepth);
            })}
          />
      </div>
      <div className="survey-item">
        <input
          id={`${e.azimuth?.toString()}-azimuth`}
          name="azimuth"
          type="number"
          title="Azimuth"
          value={e.azimuth === null ? "" : e.azimuth}
          min="0"
          onFocus={e => e.target.select()}
          onClick={(e) => {
              e.stopPropagation()
          }}
          className="survey-cell-input"
          onChange={((event) => {
             WellM?.Survey.updateSurveyCell(e.mdepth, 'azimuth', 'number', event.target.value)
             if(e.mdepth !== null)
             handleSort(e.mdepth);
          })}
        />
      </div>
    </div>
  )
})

export default SurveyTableRow
