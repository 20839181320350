import {
  Instance,
  types,
  SnapshotOut,
  detach,
  destroy,
  getParent,
} from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import Pipe, { PipeCreationAttributes } from './Pipe'
import { EditingModeEnum } from './index'

import { RawTallyRow } from 'types/models/PipeTally'
import { undoManager } from 'app/store'
import { StringInstance } from './TallyString'

export type PipeListInstance = Instance<typeof PipeList>
export type PipeListDataInstance = Instance<typeof Pipe>[]
export type PipeListDataSnapshot = SnapshotOut<typeof Pipe>[]
export type SetDataInput = PipeCreationAttributes[]
export type AddDataPosition = 'before' | 'after'
export type AddDataOptions = {
  targetId?: string
  position?: AddDataPosition
  editingMode?: EditingModeEnum
}
export type PipeListSetMode = 'stash' | 'tally'
export type RemoveDataOptions = {
  ids?: string[]
  mode: Exclude<EditingModeEnum, 'disabled'>
  destroy?: boolean
}

const PipeList = types
  .model('PipeList', {
    id: types.optional(types.identifier, uuid),
    data: types.array(Pipe),
    selected: types.array(types.string),
  })

  .views((self) => ({
    get numSelected() {
      return self.selected.length
    },
    get allSelected() {
      return self.data.length > 0 && (self.selected.length === self.data.length)
    },
    get someSelected() {
      return (
        self.selected.length > 0 && self.selected.length <= self.data.length
      )
    },
  }))

  .actions((self) => ({
    setData(
      data: SetDataInput | RawTallyRow[],
      mode: PipeListSetMode = 'tally'
    ) {
      self.data.clear()
      self.data.push(...data)
      self.data.forEach((pipe, i) => {
        if (mode === 'tally') pipe.standNumber = i + 1
        // pipe.pipeList = self.id
      })
    },

    select(id: string) {
      undoManager.withoutUndo(() => {
        const selectedIndex = self.selected.indexOf(id)
        if (selectedIndex === -1) {
          self.selected.push(id)
        } else if (selectedIndex >= 0) {
          self.selected.splice(selectedIndex, 1)
        }
      })
    },

    selectBySerialNumber(sn: string) {
      undoManager.withoutUndo(() => {
        const element = self.data.find(
          ({ serialNumber }) => serialNumber === sn
        )
        if (element) {
          this.select(element.id)
        }
      })
    },

    selectAll() {
      undoManager.withoutUndo(() => {
        if (!self.allSelected) {
          self.selected.clear()
          self.data.forEach(({ id }) => {
            if (self.selected.indexOf(id) === -1) self.selected.push(id)
          })
        } else self.selected.clear()
      })
    },

     addData(data: SetDataInput | RawTallyRow[], opts: AddDataOptions = {}) {
      const { targetId: id, position, editingMode } = opts
      let index: number | null = null

      if (id && position) {
        index = self.data.findIndex(({ id: ID }) => ID === id)
        index += position === 'before' ? 0 : 1
        self.data.splice(index, 0, ...data)

        const prevFirstStandNumber = self.data[0].standNumber as number

        let firstStandNumber =
          index !== 0
            ? prevFirstStandNumber
            : prevFirstStandNumber - data.length >= 1
            ? prevFirstStandNumber - data.length
            : 1

        if (
          (position === 'before' && editingMode === 'RIH') ||
          editingMode === 'POOH'
        ) {
          if (index !== 0 && prevFirstStandNumber - data.length >= 1)
            firstStandNumber = prevFirstStandNumber - data.length
          else firstStandNumber = 1
        }

        self.data.reduce((prev, pipe) => {
          pipe.standNumber = prev
          return prev + 1
        }, firstStandNumber)
      } else {
        self.data.push(...data)
        self.data.reduce((prev, pipe) => {
          pipe.standNumber = prev
          return prev + 1
        }, 1)
      }
    },

    removeData(opts: RemoveDataOptions) {
      let fromSelected = false
      if (!opts.ids) {
        opts.ids = self.selected
        fromSelected = true
      }

      const { ids } = opts
      let { mode } = opts

      const string = getParent(self) as StringInstance

      if (mode === 'POOH' && ids.length % string.$standLength === 0) {
        mode = 'RIH'
      }

      const firstStandNumber = self.data[0].standNumber as number

      const removed = ids.map((id) => {
        return detach(self.data[self.data.findIndex(({ id: ID }) => ID === id)])
      })

      self.data.reduce((prev, pipe) => {
              pipe.standNumber = prev
              return prev + 1
            }, firstStandNumber)


      undoManager.withoutUndo(() => {
        if (fromSelected) self.selected.clear()
      })

      return removed
    },

    deleteData(ids?: string[]) {
      let fromSelected = false
      if (!ids) {
        ids = self.selected
        fromSelected = true
      }

      ids.forEach((id) => {
        destroy(self.data[self.data.findIndex(({ id: ID }) => ID === id)])
      })

      undoManager.withoutUndo(() => {
        if (fromSelected) self.selected.clear()
      })
    },
  }))

export default PipeList
