import { Instance, types, SnapshotIn } from 'mobx-state-tree'
import SurveyRow from './SurveyRow'
export type SurveyInstance = Instance<typeof Survey>
export type SurveySetAttributes = SnapshotIn<typeof Survey>
export type SurveyRowCreationArgs = SnapshotIn<typeof SurveyRow>

const Survey = types
  .model('Survey', {
    rows: types.optional(types.array(SurveyRow), []),
  })
  .actions((self) => {
    return {

      createSurveyRow(
        data: {mdepth: number, inclination: number, azimuth: number},
      ) {
        self.rows.push(...[data])
      },

      updateSurveyCell(depth: number | null, field: any, fieldType: string, value: string) {
        const row: any = self.rows.find((e) => e.mdepth === depth)
        if (row) {
          const updateFieldType: string = fieldType
          const updateField: typeof updateFieldType = field
          const updateValue = value

          if (updateFieldType === 'string') {
            row[updateField] = updateValue
          } else if (updateFieldType === 'number') {
            row[updateField] = Number(updateValue)
          } else if (updateFieldType === 'boolean') {
            row[updateField] = updateValue === 'true'
          } else if (updateFieldType === 'array') {
            row[updateField] = updateValue.split(',')
          } else if (updateFieldType === 'object') {
            row[updateField] = JSON.parse(updateValue)
          } else if (updateFieldType === 'enum') {
            row[updateField] = updateValue
          } else if (updateFieldType === 'reference') {
            row[updateField] = updateValue
          } else {
            row[updateField] = updateValue
          }
        }
      },

      sortSurveyRows() {
          self.rows.sort((a, b) => a.mdepth === null ? 1 : b.mdepth === null ? -1 : a.mdepth - b.mdepth);
      },

      deleteSurveyRow(depth: number | null) {
        const deleteKey = self.rows.findIndex((e) => e.mdepth === depth)
        if (deleteKey !== -1) {
          self.rows.splice(deleteKey, 1)
        } else {
          console.log('deleteSurveyRow: row not found')
        }
      },

      createEmptyRow() {
        self.rows.push(
          SurveyRow.create({mdepth: null, azimuth: null, inclination: null})
        )
      },
    }
  })

export type SurveyRowInstance = Instance<typeof SurveyRow>

export default Survey
