import { useMst } from 'app/store'
import { observer } from 'mobx-react'
import { useCallback, MouseEventHandler } from 'react'
import Dialog from '../kit/Dialog'
import { Button } from '../kit/SmallWidthButton'

const DeletePipe = observer(({ open, onClose: oc }:DeletePipeProps ) => {
  const {
    store: { PipeTally },
  } = useMst()

  const onClose = useCallback(() => {
    oc()
    setTimeout(() => {
    }, 250)
  }, [oc])

  const handleTrash: MouseEventHandler<HTMLButtonElement> = () => {
    PipeTally?.storePipe({
      direction: 'trash',
    })
    onClose()
  }

const saveWildSingle:MouseEventHandler<HTMLButtonElement> = (e) => {
  if(PipeTally?.currentString?.list?.selected) {
    for(const selectedPipeId of PipeTally?.currentString.list.selected) {
      const thisPipe = PipeTally?.currentString.list.data.find((p) => p.id === selectedPipeId);
      if(thisPipe) PipeTally?.currentString.createWildSingle(thisPipe.length, thisPipe.serialNumber, thisPipe.isTool)
    }
    handleTrash(e);
  }
}
 
  
  return (
    <Dialog
      style={{ padding: '0px 32px 24px 32px' }}
      open={open}
      onClose={onClose}
    >
      <h3 style={{ textAlign: 'center' }}>Delete Pipe</h3>
      
      
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem'}}>
        <Button
          small
          secondary
          style={{ marginRight: '8px' }}
          onClick={handleTrash}
        >
          Delete Pipe
        </Button>

        <Button
          small
          secondary
          style={{ marginRight: '8px' }}
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          small
          secondary
          style={{ marginRight: '8px' }}
          onClick={saveWildSingle}
        >
          Save to Wild Single
        </Button>
      </div>
    </Dialog>
  )
})

type DeletePipeProps = {
  open: boolean
  onClose: () => void
}

export default DeletePipe
