import { observer } from 'mobx-react'
import SurveyTable from './SurveyTable'
import TableToolbar from './TableToolbar'
import '../Survey/Survey.css'

const Survey = observer(() => {

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TableToolbar />
                <SurveyTable  />
            </div>
        </div>
    )
})

export default Survey
