import { useQuery, gql, useMutation } from '@apollo/client'
import { produce } from 'immer'
import { useState, useContext } from 'react'
import { CompanyContext, RigContext } from 'App'
import { useNavigate } from 'react-router'
import { spaceToUnderscore, removeNonAlphaNumericExceptSpacePeriodHyphen } from 'utils/helpers'
import Dialog from './kit/Dialog'
import Input from './kit/Input'
import { Button, ImportButton } from './kit/SmallWidthButton'
import { RadioButton } from './kit/RadioButton'
import './Wells.css'

const Head = ({sortByCreationDate, setSortByCreationDate}) => 
<thead>
  <tr>
    <th>Well Name</th>
    <th>Location</th>
    <th>
      <div className={'sort-date-nav'}  style={{ cursor: 'pointer' }} onClick={() => {setSortByCreationDate(true)}}>Date Created
      <span style={{color: sortByCreationDate ? '#fff' :''}}>&nbsp; &uarr;</span></div></th>
    <th>
      <div className={'sort-date-nav'}  style={{ cursor: 'pointer' }} onClick={() => {setSortByCreationDate(false)}} >Last Updated 
      <span style={{color: sortByCreationDate ? '' : '#fff'}}>&nbsp; &uarr;</span></div></th>
    <th>Units</th>
    <th>Status</th>
  </tr>
</thead>

const CreateWell = ({ refetch, open, onClose, companyId }) => {
  const [form, setForm] = useState({ name: '' })
  const [unit, setUnit] = useState('')

  const [createWell] = useMutation(
    gql`
      mutation createWell($name: String!, $loacation: String!, $companyId: String!, $rigId: String!, $units: String!) {
        createWell(
            name: $name
            loacation: $loacation
            company: $companyId
            rig: $rigId
            units: $units
        ) {
          id
        }
      }
    `,
    {
      variables: {
        rigId: JSON.parse(localStorage.getItem('context')).rig.id,
    },
      onCompleted() {
        refetch()
        setForm({ name: '' })
        onClose()
      },
    }
  )

  const handleName = (e) => {
    setForm(
      produce(form, (draft) => {
        draft.name = sanitizeInput(e.target.value)
      })
    )
  }

  const handleLoacation = (e) => {
    setForm(
      produce(form, (draft) => {
        draft.loacation = e.target.value
      })
    )
  }

  const handleUnits = {
    imperial() {
      setUnit('imperial')
      setForm(
        produce(form, (draft) => {
          if (draft.units !== 'imperial') draft.units = 'imperial'
        })
      )
    },
    metric() {
      setUnit('metric')
      setForm(
        produce(form, (draft) => {
          if (draft.units !== 'metric') draft.units = 'metric'
        })
      )
    },
    hybrid() {
      setUnit('hybrid')
      setForm(
        produce(form, (draft) => {
          if (draft.units !== 'hybrid') draft.units = 'hybrid'
        })
      )
    },
  }

  function sanitizeInput(str) {
    return removeNonAlphaNumericExceptSpacePeriodHyphen(str)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="createWellWrapper">
        <h3 className="createWellHeading">Create new well</h3>

        <h4 className="createWellSubheading">Name</h4>
        <div>
          <Input
            id="wellName"
            title=""
            onChange={handleName}
            onKeyUp={(e) => {
              e.target.value = sanitizeInput(e.target.value)
            }}
            className="inputModalField"
            labelClassName="placeholder"
            wrapperClassName="field"
            style={{ width: 400, marginTop: 0 }}
          />
          <div className="createWellFinePrint">* Alphanumeric, period, hyphen and spaces only</div>
        </div>

        <h4 className="createWellSubheading">Location</h4>
        <div>
          <Input
            id="loacation"
            title=""
            onChange={handleLoacation}
            className="inputModalField"
            labelClassName="placeholder"
            wrapperClassName="field"
            style={{ width: 400, marginTop: 0 }}
          />
        </div>

        <h4 className="createWellSubheading">Units</h4>
        <div className="createWellPickerContainer">
          <div className="direction-picker" onClick={handleUnits.imperial}>
            <RadioButton
              id="select-before"
              checked={unit === 'imperial'}
              onChange={() => {}}
            />
            <label className="createWellPickerLabel">Imperial</label>
          </div>
          <div className="direction-picker" onClick={handleUnits.metric}>
            <RadioButton
              id="select-after"
              checked={unit === 'metric'}
              onChange={() => {}}
            />
            <label className="createWellPickerLabel">Metric</label>
          </div>
          <div className="direction-picker" onClick={handleUnits.hybrid}>
            <RadioButton
              id="select-hybrid"
              checked={unit === 'hybrid'}
              onChange={() => {}}
            />
            <label className="createWellPickerLabel">Hybrid</label>
          </div>
        </div>

        <div className="createWellConfirmBtnWrapper">
          <Button
            small
            disabled={!form.name || !form.units || !form.loacation}
            onClick={() => {
              createWell({ variables: { ...form, companyId } })
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

const Wells = () => {
  const [open, setOpen] = useState(false)
  const [sortByCreationDate, setSortByCreationDate] = useState(false);
  const { company } = useContext(CompanyContext)
  const { rig } = useContext(RigContext)
  const router = useNavigate()

  const { data, loading, refetch } = useQuery(
    gql`
      query getWells($companyId: String!, $rigId: String!, $take: String!) {
        getWells(
            companyId: $companyId,
            rigId: $rigId,
            take: $take,
          ) {
          id
          rigId
          name
          loacation
          units
          createdAt
          data
        }
      }
    `,
    {
      variables: {
        companyId: company?.id,
        rigId: rig?.id,
        take: '500',
      },
    }
  )

  return (
    <>
      <CreateWell
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        refetch={refetch}
        companyId={JSON.parse(localStorage.getItem('context')).company.id}
      />
      <div className="createWellBtnWrapper doNotPrint">
        <ImportButton
          title="Create well"
          onClick={() => setOpen(true)}
        />
      </div>
      <div className="wellTableContainer">
        <table className="wellTable allWellsTable">
          <Head sortByCreationDate={sortByCreationDate} setSortByCreationDate={setSortByCreationDate}/>
          <tbody>
            {!loading &&
              data?.getWells
              .toSorted((a, b) => {
                const aUpdatedDate = (typeof a.data === 'string' ? JSON.parse(a.data) : a.data).UpdatedAt;
                const bUpdatedDate = (typeof b.data === 'string' ? JSON.parse(b.data) : b.data).UpdatedAt;
                const aComparisonDate = !sortByCreationDate && aUpdatedDate ? aUpdatedDate : new Date(a.createdAt).getTime();
                const bComparisonDate = !sortByCreationDate && bUpdatedDate ? bUpdatedDate : new Date(b.createdAt).getTime();
                return bComparisonDate - aComparisonDate;
              }
            )
            .map(({ id, name, units, loacation, data: welldata, createdAt }) => {
                const parsedData = (typeof welldata === 'string' ? JSON.parse(welldata) : welldata);
                const status = parsedData.Status;
                const wellUpdated = (parsedData.UpdatedAt ? new Date(parsedData.UpdatedAt) : new Date(createdAt)).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'});
                const wellUpdatedTime = (parsedData.UpdatedAt ?  new Date(parsedData.UpdatedAt) : new Date(createdAt)).toLocaleTimeString()

                
                return (
                <tr
                  key={id}
                  onClick={() => {
                    router(`/all-wells/${spaceToUnderscore(name)}`)
                  }}
                >
                  <td>{name}</td>
                  <td>{loacation}</td>
                  <td>
                    {new Date(createdAt).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'})}
                  </td>
                  <td>
                    {wellUpdated} 
                    <span style={{ marginLeft: '9px', color: '#67687e' }}>
                      {wellUpdatedTime}
                    </span>
                  </td>
                  <td>{units}</td>
                  <td>
                    <span style={{ fontSize: '10pt', textWrap: 'nowrap' }} className={status === 'completed' ? 'uiContainerStatusActive' : 'uiContainerStatusWarning'}>
                      {status === 'completed' ? 'Completed' : 'In Progress'}
                    </span>
                  </td>
                </tr>
              )})}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Wells
